import React, { useEffect } from "react"
import { Router } from "@reach/router"
import { navigate } from "gatsby"
import Layout from "../../components/structure/Layout/Layout"
import PrivateAnnuaireIndex from '../../components/pages/Private/Annuaire/Index';
import PrivateAnnuaireMemberPage from "../../components/pages/Private/Annuaire/Members/Member/Member";
import PrivateAnnuaireMembersPage from "../../components/pages/Private/Annuaire/Members/Index";
import { isLoggedIn } from "../../services/AuthService";

const AnnuairePage = () => {

  useEffect(() => {
    if(!isLoggedIn()){
      navigate('/annuaire');
    }
  }, []);

  return (
    <Layout current='lannuaire'>
        <Router basepath="/annuaire" className="page-wrapper" data-theme="quaternary-25">
          <PrivateAnnuaireIndex path="/" />
          <PrivateAnnuaireMembersPage path="/membres" />
          <PrivateAnnuaireMemberPage path="/membres/:slug" />
        </Router>
    </Layout>
  );
}

export default AnnuairePage