import React, { FunctionComponent, useEffect, useState } from 'react';

import "./styles-dept.scss";



const FranceDept: FunctionComponent<{
	data?: Object,
}> = ({
	data
}) => {


		return (
			<div className="map-wrapper">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 388 450">
					<defs>
						<filter id="f2" x="0" y="0" width="200%" height="200%">
							<feOffset result="offOut" in="SourceGraphic" dx="0" dy="0" />
							<feGaussianBlur result="blurOut" in="offOut" stdDeviation="10" />
							<feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
						</filter>
					</defs>
					<g className="map-svg map-fr map-fr-dept">
						<g>							
							<polygon data-color={data[77]} fill="currentColor" points="207.14,144.96 200.94,142.66 202.52,148.4 204.64,155.14 202.4,161.53 200.61,166.98 
			201.87,173.2 195.99,177.64 200.94,179.76 199.69,183.16 202.81,185.36 208.11,182.87 213.08,182.87 215.28,179.45 213.71,176.94 
			223.35,173.2 223.42,168.87 226.18,165.09 225.54,160.43 222.44,158.13 225.33,154.39 216.32,142.76 212.47,144.62 		"/>

							<polygon data-color={data[47]} fill="currentColor" points="134.29,357.88 142.38,354.43 145.49,356 151.74,352.26 154.23,346.99 152.36,344.78 153.6,342.62 
			158.28,342.31 156.09,336.06 158.11,332.92 151.35,328.46 137.73,328.76 132.48,325.63 127.3,335.6 122.92,348.83 131.2,351.17 
			127.91,358.1 132.12,356.32"/>
							<polygon data-color={data[33]} fill="currentColor" points="102.21,338.09 108.28,337.62 117.48,345.42 116.23,348.83 122.92,348.83 127.3,335.6 
			132.48,325.63 128.08,322.97 128.08,310.85 117.48,307.11 111.89,300.25 105.93,299.67 107.22,308.67 111.59,317.37 
			106.59,311.78 103.16,300.59 96,292.78 94.13,294.66 90.1,329.53 93.83,326.41 93.83,330.78 91.01,333.57 90.35,337.48 
			98.96,335.48"/>
							<polygon data-color={data[40]} fill="currentColor" points="94.9,378.11 100.05,374.68 117.48,375.78 117.48,372.17 117.48,369.07 119.98,368.15 
			119.98,360.65 124.04,359.74 127.91,358.1 131.2,351.17 122.92,348.83 116.23,348.83 117.48,345.42 108.28,337.62 102.21,338.09 
			98.96,335.48 90.35,337.48 85.72,364.11 81.98,370.95 81.98,373.76 80.99,374.89 92.43,374.91"/>
							<polygon data-color={data[64]} fill="currentColor" points="115.77,397.43 119.98,395.87 124.65,387.46 123.09,385.29 123.41,374.99 117.48,372.17 
			117.48,375.78 100.05,374.68 94.9,378.11 92.43,374.91 80.99,374.89 73.58,383.1 78.26,386.83 81.69,385.89 83.53,389.63 
			81.69,394.63 89.45,396.18 93.83,400.22 103.16,401.16 107.22,407.39 115.72,404.89 115.72,404.89"/>

							<polygon data-color={data[24]} fill="currentColor" points="151.35,328.46 158.11,332.92 161.1,328.29 164.5,327.97 169.19,320.83 169.19,313.35 
			169.19,310.85 165.44,309.62 164.2,298.08 158.91,293.73 157.67,289.98 151.74,289.98 151.12,285.93 145.49,285.61 142.71,287.82 
			141.14,294.03 135.55,297.76 134.63,303.38 128.38,307.44 128.08,310.85 128.08,322.97 132.48,325.63 137.73,328.76 		"/>
							<polygon data-color={data[3]} fill="currentColor" points="222.44,243.27 218.71,244.82 215.28,240.77 210.59,240.77 208.72,242.94 206.25,242.94 
			201.87,247.32 201.87,252.3 196.6,251.69 193.16,256.36 200.61,264.14 201.72,269.46 211.54,263.22 215.58,269.13 225.88,269.74 
			234.27,275.35 237.39,272.53 238.01,263.51 242.35,258.84 240.82,253.85 234.27,250.12 230.84,242.67 227.41,245.76 		"/>
							<polygon data-color={data[63]} fill="currentColor" points="215.58,269.13 211.54,263.22 201.72,269.46 202.81,274.73 197.18,280.03 200.61,284.37 
			199.69,285.93 199.69,290.6 201.26,292.16 201.26,296.54 218.08,301.51 222.44,297.13 240.49,298.38 242.98,295.25 234.57,282.2 
			236.14,277.53 234.27,275.35 225.88,269.74"/>
							<polygon data-color={data[15]} fill="currentColor" points="222.44,305.25 218.08,301.51 201.26,296.54 195.34,299.33 195.34,302.76 191.59,306.81 
			191.59,310.85 189.09,313.04 189.09,316.78 189.09,319.88 190.99,321.75 191.91,331.71 200,331.71 208.4,319.24 211.84,321.75 
			214.02,327.97 218.08,320.83 220.25,320.83 223.99,317.37 224.92,310.22 		"/>
							<polygon data-color={data[43]} fill="currentColor" points="252.93,298.38 240.49,298.38 222.44,297.13 218.08,301.51 222.44,305.25 224.92,310.22 
			223.99,317.37 226.18,317.7 228.36,321.44 235.82,321.44 241.44,326.12 244.22,318.94 248.91,318.94 254.5,312.72 254.5,307.72 
			257.31,307.72 257.31,303.99 253.9,302.43 		"/>
							<polygon data-color={data[79]} fill="currentColor" points="134.63,270.08 134.92,266.31 128.38,256.66 130.87,248.27 128.38,237.98 130.25,233.61 
			127.46,227.07 116.86,227.07 111.89,231.13 105.35,229.87 111.89,244.82 110.94,253.56 115.61,258.23 108.75,258.23 
			110.01,263.82 121.22,268.18 126.51,274.09 129.33,269.13 		"/>
							<polygon data-color={data[86]} fill="currentColor" points="154.85,261.63 158.28,261.63 163.57,256.66 161.1,251.38 158.28,251.38 155.79,248.88 
			155.79,243.27 146.14,231.76 142.71,234.56 139.9,234.56 139.9,229.87 132.74,223.66 127.46,227.07 130.25,233.61 128.38,237.98 
			130.87,248.27 128.38,256.66 134.92,266.31 134.63,270.08 140.54,271.61 143.65,268.5 145.81,270.08 151.9,266.01 151.9,264.6 		
			"/>
							<polygon data-color={data[17]} fill="currentColor" points="117.48,307.11 128.08,310.85 128.38,307.44 122.92,301.19 118.73,300.25 121.07,293.57 
			116.53,282.2 124.04,282.04 126.51,274.09 121.22,268.18 110.01,263.82 108.75,258.23 102.84,258.23 97.57,260.39 93.19,267.25 
			98.15,273.48 95.37,279.09 96.94,282.51 92.24,282.82 92.24,286.56 99.1,289.98 105.64,297.47 105.93,299.67 111.89,300.25 		"/>
							<polygon data-color={data[16]} fill="currentColor" points="135.55,297.76 141.14,294.03 142.71,287.82 145.49,285.61 151.74,277.53 156.09,275.66 
			156.09,272.23 151.9,272.23 151.9,266.01 145.81,270.08 143.65,268.5 140.54,271.61 134.63,270.08 129.33,269.13 126.51,274.09 
			124.04,282.04 116.53,282.2 121.07,293.57 118.73,300.25 122.92,301.19 128.38,307.44 134.63,303.38 		"/>
							<polygon data-color={data[12]} fill="currentColor" points="218.93,336.24 214.02,327.97 211.84,321.75 208.4,319.24 200,331.71 191.91,331.71 187.86,335.13 
			182.58,338.88 184.13,341.67 181.94,343.86 185.69,346.04 186.32,351.65 190.99,350.08 200.61,354.74 204.99,366.59 
			210.59,366.29 213.17,371.58 218.01,367.45 218.45,364.42 223.35,364.42 223.35,362.61 227.26,360.06 227.26,355.46 
			223.67,353.27 226.34,349.93 219.55,346.36 		"/>
							<polygon data-color={data[81]} fill="currentColor" points="204.99,366.59 200.61,354.74 190.99,350.08 186.32,351.65 184.13,353.51 180.07,353.82 
			173.56,360.98 173.85,364.42 177.58,370.02 177.29,373.44 178.83,373.44 185.99,383.45 190.44,384.35 193.62,380.28 202.96,382 
			205.62,372.29 208.63,374.07 213.17,371.58 210.59,366.29 		"/>
							<polygon data-color={data[31]} fill="currentColor" points="155.49,399.92 157.33,392.74 165.14,394.93 166.06,389.32 176.18,389.01 181.19,382.46 
			185.99,383.45 178.83,373.44 177.29,373.44 177.58,370.02 173.85,364.42 173.56,360.98 165.75,362.55 164.2,367.22 159.83,365.03 
			154.85,365.98 162.63,374.07 156.09,377.18 155.79,385.29 151.74,383.1 147.68,385.89 139.6,391.52 143.33,398.67 139.6,403.96 
			139.37,410.89 144.89,411.74 143.65,407.08 145.49,405.22 151.45,407.03 151.12,403.04 		"/>
							<polygon data-color={data[65]} fill="currentColor" points="143.33,398.67 139.6,391.52 147.68,385.89 134.63,382.79 130.87,384.01 131.2,379.99 
			126.51,374.07 123.41,374.99 123.09,385.29 124.65,387.46 119.98,395.87 115.77,397.43 115.72,404.89 115.72,404.89 
			121.22,411.13 127.13,412.37 129,411.13 132.42,412.06 136.79,410.49 139.37,410.89 139.6,403.96 		"/>
							<polygon data-color={data[32]} fill="currentColor" points="145.49,356 142.38,354.43 134.29,357.88 132.12,356.32 127.91,358.1 124.04,359.74 119.98,360.65 
			119.98,368.15 117.48,369.07 117.48,372.17 123.41,374.99 126.51,374.07 131.2,379.99 130.87,384.01 134.63,382.79 147.68,385.89 
			151.74,383.1 155.79,385.29 156.09,377.18 162.63,374.07 154.85,365.98 152.36,361.61 		"/>
							<polygon data-color={data[82]} fill="currentColor" points="184.13,353.51 186.32,351.65 185.69,346.04 181.94,343.86 176.66,343.86 173.56,346.99 
			170.42,345.1 166.39,347.92 158.28,342.31 153.6,342.62 152.36,344.78 154.23,346.99 151.74,352.26 145.49,356 152.36,361.61 
			154.85,365.98 159.83,365.03 164.2,367.22 165.75,362.55 173.56,360.98 180.07,353.82 		"/>
							<polygon data-color={data[9]} fill="currentColor" points="190.73,413.23 189.02,409.1 184.03,409.87 181.71,404.99 184.68,404.58 184.28,401.24 
			185.69,400.3 183.5,393.85 176.18,389.01 166.06,389.32 165.14,394.93 157.33,392.74 155.49,399.92 151.12,403.04 151.45,407.03 
			166.69,411.74 169.79,415.48 174.48,415.78 176.97,419.52 184.82,414.79 		"/>
							<polygon data-color={data[66]} fill="currentColor" points="207.48,406.45 196.92,406.15 196.92,411.74 190.73,413.23 184.82,414.79 176.97,419.52 
			187.23,425.76 194.73,423.9 204.69,427.64 207.16,423.9 216.2,422.02 220.55,424.21 220.55,419.83 217.76,417.97 217.47,405.53 
			212.16,403.04 		"/>
							<polygon data-color={data[11]} fill="currentColor" points="209.03,389.32 202.81,385.58 202.96,382 193.62,380.28 190.44,384.35 185.99,383.45 
			181.19,382.46 176.18,389.01 183.5,393.85 185.69,400.3 184.28,401.24 184.68,404.58 181.71,404.99 184.03,409.87 189.02,409.1 
			190.73,413.23 196.92,411.74 196.92,406.15 207.48,406.45 212.16,403.04 217.47,405.53 217.43,403.66 220.87,399.92 219.3,395.87 
			224.44,391.3 211.54,385.29 		"/>
							<polygon data-color={data[34]} fill="currentColor" points="238.01,358.81 232.39,362.21 227.26,360.06 223.35,362.61 223.35,364.42 218.45,364.42 
			218.01,367.45 213.17,371.58 208.63,374.07 205.62,372.29 202.96,382 202.81,385.58 209.03,389.32 211.54,385.29 224.44,391.3 
			227.73,388.39 229.28,389.32 233.95,382.79 237.06,384.65 247.04,377.18 249.53,368.47 		"/>
							<polygon data-color={data[30]} fill="currentColor" points="262.28,364.42 266.91,360.31 270.71,356.94 264.47,345.73 255.13,341.67 252.34,345.1 247.66,342 
			246.41,336.7 242.03,340.44 244.22,348.83 239.25,351.96 233.95,349.47 226.34,349.93 223.67,353.27 227.26,355.46 227.26,360.06 
			232.39,362.21 238.01,358.81 249.53,368.47 247.04,377.18 249.53,380.91 252.34,380.75 261.36,371.25 		"/>
							<polygon data-color={data[48]} fill="currentColor" points="241.44,326.12 235.82,321.44 228.36,321.44 226.18,317.7 223.99,317.37 220.25,320.83 
			218.08,320.83 214.02,327.97 218.93,336.24 219.55,346.36 226.34,349.93 233.95,349.47 239.25,351.96 244.22,348.83 
			242.03,340.44 246.41,336.7 241.75,331.4 		"/>
							<polygon data-color={data[4]} fill="currentColor" points="320.22,333.57 311.81,332.35 304.32,335.48 298.73,342 301.21,346.65 294.06,346.04 287.84,350.4 
			292.19,353.51 293.11,361.61 298.1,367.22 303.39,365.67 305.88,367.52 310.57,363.16 314.31,364.42 317.73,361.29 322.7,361.29 
			325.19,357.56 330.5,356.94 329.55,353.2 326.77,352.88 322.7,344.17 327.38,339.49 330.23,327.29 326.44,325.48 		"/>
							<polygon data-color={data[5]} fill="currentColor" points="313.04,308.98 309.32,306.81 307.75,310.85 311.49,315.23 311.49,319.24 307.13,317.7 
			297.78,321.75 294.99,324.54 292.19,327.35 289.69,334.83 285.64,334.53 286.9,340.44 291.56,341.67 294.06,346.04 301.21,346.65 
			298.73,342 304.32,335.48 311.81,332.35 320.22,333.57 326.44,325.48 330.23,327.29 331.11,323.62 320.22,307.72 315.26,307.44 		
			"/>
							<polygon data-color={data[6]} fill="currentColor" points="338.58,366.91 349.19,362.85 349.19,356.94 347.01,355.38 352.28,345.73 348.26,342.91 
			342.95,346.36 327.38,339.49 322.7,344.17 326.77,352.88 329.55,353.2 330.5,356.94 325.19,357.56 322.7,361.29 326.14,367.22 
			332.98,374.37 338.58,371.25 		"/>
							<polygon data-color={data[83]} fill="currentColor" points="322.7,361.29 317.73,361.29 314.31,364.42 310.57,363.16 305.88,367.52 303.39,365.67 
			298.1,367.22 294.68,375.31 296.87,379.67 294.06,383.1 297.48,385.89 293.75,390.57 302.46,395.56 304.03,392.14 307.75,393.98 
			311.49,390.87 315.55,392.74 324.91,388.06 324.91,383.1 332.68,378.11 332.98,374.37 326.14,367.22 		"/>
							<polygon data-color={data[84]} fill="currentColor" points="292.19,353.51 287.84,350.4 276.94,346.04 276.94,342.91 274.12,343.22 275.04,338.57 
			272.56,336.7 270.39,340.13 271.33,343.53 269.76,344.49 266.34,342.91 264.47,345.73 270.71,356.94 266.91,360.31 277.85,366.29 
			285.35,366.59 292.49,370.02 298.1,367.22 293.11,361.61 		"/>
							<polygon data-color={data[13]} fill="currentColor" points="294.06,383.1 296.87,379.67 294.68,375.31 298.1,367.22 292.49,370.02 285.35,366.59 
			277.85,366.29 266.91,360.31 262.28,364.42 261.36,371.25 252.34,380.75 261.36,380.28 264.15,384.65 268.85,384.65 270.71,380.6 
			276.94,385.89 283.48,383.1 285.97,384.65 285.97,389.32 293.75,390.57 297.48,385.89 		"/>
							<polygon data-color={data[7]} fill="currentColor" points="262.91,335.48 266.34,327.97 269.46,320.51 266.03,308.98 266.65,302.14 264.15,299.02 
			257.31,303.99 257.31,307.72 254.5,307.72 254.5,312.72 248.91,318.94 244.22,318.94 241.44,326.12 241.75,331.4 246.41,336.7 
			247.66,342 252.34,345.1 255.13,341.67 264.47,345.73 266.34,342.91 		"/>
							<polygon data-color={data[26]} fill="currentColor" points="288.45,321.75 286.59,309.62 278.48,310.85 276.94,303.68 270.07,299.65 266.65,302.14 
			266.03,308.98 269.46,320.51 266.34,327.97 262.91,335.48 266.34,342.91 269.76,344.49 271.33,343.53 270.39,340.13 272.56,336.7 
			275.04,338.57 274.12,343.22 276.94,342.91 276.94,346.04 287.84,350.4 294.06,346.04 291.56,341.67 286.9,340.44 285.64,334.53 
			289.69,334.83 292.19,327.35 294.99,324.54 		"/>
							<polygon data-color={data[38]} fill="currentColor" points="269.16,289.69 268.2,294.66 265.73,294.98 264.15,299.02 266.65,302.14 270.07,299.65 
			276.94,303.68 278.48,310.85 286.59,309.62 288.45,321.75 294.99,324.54 297.78,321.75 307.13,317.7 311.49,319.24 311.49,315.23 
			307.75,310.85 309.32,306.81 305.26,305.55 306.2,295.9 299.65,292.78 298.1,295.25 290.94,293.1 291.27,287.82 282.21,276.91 
			274.12,281.26 275.4,284.37 		"/>
							<polygon data-color={data[42]} fill="currentColor" points="261.98,291.85 262.28,289.98 257.93,289.98 253.26,285.93 254.5,281.26 250.47,269.44 
			253.9,267.25 253.26,264.77 244.22,263.51 242.35,258.84 238.01,263.51 237.39,272.53 234.27,275.35 236.14,277.53 234.57,282.2 
			242.98,295.25 240.49,298.38 252.93,298.38 253.9,302.43 257.31,303.99 264.15,299.02 265.73,294.98 		"/>
							<polygon data-color={data[69]} fill="currentColor" points="256.36,261.33 252.93,260.09 253.26,264.77 253.9,267.25 250.47,269.44 254.5,281.26 
			253.26,285.93 257.93,289.98 262.28,289.98 261.98,291.85 265.73,294.98 268.2,294.66 269.16,289.69 275.4,284.37 274.12,281.26 
			269.46,280.31 267.29,275.35 263.52,275.66 262.6,270.37 264.78,266.31 263.86,262.57 259.79,259.17 		"/>
							<polygon data-color={data[1]} fill="currentColor" points="291.56,259.46 290.94,256.97 288.45,256.66 284.72,260.39 279.13,253.24 273.51,250.74 
			269.16,251.98 263.86,262.57 264.78,266.31 262.6,270.37 263.52,275.66 267.29,275.35 269.46,280.31 274.12,281.26 282.21,276.91 
			291.27,287.82 292.49,274.09 294.06,267.57 299.35,262.57 299.56,254.78 294.68,259.46 		"/>
							<polygon data-color={data[74]} fill="currentColor" points="292.49,274.09 309.01,284.06 314.92,272.85 319.6,280.03 324.91,278.47 328.63,274.41 
			325.19,267.57 323.32,268.18 321.76,256.36 319.27,253.85 307.43,256.36 302.16,262.88 299.35,262.57 294.06,267.57 		"/>
							<polygon data-color={data[73]} fill="currentColor" points="314.92,272.85 309.01,284.06 292.49,274.09 291.27,287.82 290.94,293.1 298.1,295.25 
			299.65,292.78 306.2,295.9 305.26,305.55 309.32,306.81 313.04,308.98 315.26,307.44 320.22,307.72 329.55,303.07 333.6,293.73 
			327.69,281.91 325.19,280.95 324.91,278.47 319.6,280.03"/>
							<polygon data-color={data[87]} fill="currentColor" points="181.31,280.31 175.72,278.14 171.06,266.31 168.56,265.07 171.35,261.33 169.67,258.04 
			163.57,256.66 158.28,261.63 154.85,261.63 151.9,264.6 151.9,266.01 151.9,272.23 156.09,272.23 156.09,275.66 151.74,277.53 
			145.49,285.61 151.12,285.93 151.74,289.98 157.67,289.98 158.91,293.73 164.2,298.08 182.88,285.93 		"/>
							<polygon data-color={data[23]} fill="currentColor" points="197.18,280.03 202.81,274.73 201.72,269.46 200.61,264.14 193.16,256.36 190.99,256.28 
			187.7,256.21 186.32,255.1 184.45,256.04 179.29,254.95 178.37,257.89 170.74,256.51 169.67,258.04 171.35,261.33 168.56,265.07 
			171.06,266.31 175.72,278.14 181.31,280.31 182.88,285.93 188.17,283.74 195.34,286.56 199.69,285.93 200.61,284.37 		"/>
							<polygon data-color={data[19]} fill="currentColor" points="180.07,316.78 189.09,316.78 189.09,313.04 191.59,310.85 191.59,306.81 195.34,302.76 
			195.34,299.33 201.26,296.54 201.26,292.16 199.69,290.6 199.69,285.93 195.34,286.56 188.17,283.74 182.88,285.93 164.2,298.08 
			165.44,309.62 169.19,310.85 169.19,313.35 175.43,313.35 		"/>
							<polygon data-color={data[44]} fill="currentColor" points="86.35,229.87 91.64,232.99 95.05,227.07 96.62,215.88 104.72,215.24 103.16,205.28 99.42,203.72 
			98.15,198.12 95.7,198.43 92.88,196.24 86.67,198.12 86.05,201.22 79.17,199.99 72.96,202.48 72.96,206.83 61.73,209.95 
			59.25,214 59.25,218.05 63.93,220.52 69.2,217.41 76.68,219.91 69.85,220.86 67.03,224.29 74.83,229.87 85.72,233.93 		"/>
							<polygon data-color={data[49]} fill="currentColor" points="116.86,227.07 127.46,227.07 132.74,223.66 132.74,220.23 136.49,211.21 136.49,206.22 
			119.68,198.12 113.73,199.99 98.15,198.12 99.42,203.72 103.16,205.28 104.72,215.24 96.62,215.88 95.05,227.07 105.35,229.87 
			111.89,231.13 		"/>
							<polygon data-color={data[53]} fill="currentColor" points="119.68,198.12 121.54,190.32 125.9,188.16 125.28,183.16 128.38,180.04 127.46,176.32 
			129.95,172.27 127.13,169.47 127.13,165.09 123.41,165.09 115,170.72 111.89,169.47 109.7,170.72 107.22,166.98 101.92,166.98 
			103.16,175.07 101.29,176.63 101.29,182.87 104.1,185.36 102.21,190.32 98.15,192.21 98.15,198.12 113.73,199.99 		"/>
							<polygon data-color={data[72]} fill="currentColor" points="144.25,205.91 147.68,203.72 154.23,192.21 153.31,187.54 156.74,185.36 153.6,180.98 
			141.47,175.4 141.8,171.34 135.55,169.76 135.55,172.27 129.95,172.27 127.46,176.32 128.38,180.04 125.28,183.16 125.9,188.16 
			121.54,190.32 119.68,198.12 136.49,206.22 142.38,204.04 		"/>
							<polygon data-color={data[85]} fill="currentColor" points="108.75,258.23 115.61,258.23 110.94,253.56 111.89,244.82 105.35,229.87 95.05,227.07 
			91.64,232.99 86.35,229.87 85.72,233.93 74.83,229.87 68.61,236.72 75.15,242.67 75.45,251.69 91.64,261.01 97.57,260.39 
			102.84,258.23 		"/>
							<polygon data-color={data[22]} fill="currentColor" points="33.4,168.85 34.02,175.68 43.35,180.04 46.79,175.4 57.38,181.92 59.87,185.66 62.37,179.12 
			66.42,181.92 69.2,180.04 79.17,173.2 78.26,166.03 67.66,161.07 62.04,161.98 59.56,166.03 57.69,166.03 48.05,149.86 
			34.66,152.34 33.74,156.38 35.27,162.63 		"/>
							<polygon data-color={data[56]} fill="currentColor" points="72.96,202.48 75.45,195.93 74.2,188.77 69.2,180.04 66.42,181.92 62.37,179.12 59.87,185.66 
			57.38,181.92 46.79,175.4 43.35,180.04 34.02,175.68 29.36,181.6 38.38,188.16 32.39,197.05 39.01,199.06 43.05,208.08 
			45.84,205.6 51.47,207.45 61.73,209.95 72.96,206.83 		"/>
							<polygon data-color={data[29]} fill="currentColor" points="29.36,181.6 34.02,175.68 33.4,168.85 35.27,162.63 33.74,156.38 28.41,155.76 27.78,158.57 
			23.44,158.25 22.51,155.13 17.5,154.83 15.63,157.34 12.23,155.76 -0.23,163.55 1.32,168.85 13.16,169.47 14.1,173.84 5.7,172.27 
			5.7,175.99 11.62,176.32 11.29,180.04 2.58,180.36 2.26,184.72 5.38,184.1 9.72,189.7 9.11,193.76 14.71,195 14.4,191.28 
			24.7,194.68 32.39,197.05 38.38,188.16 		"/>
							<polygon data-color={data[35]} fill="currentColor" points="69.2,180.04 74.2,188.77 75.45,195.93 72.96,202.48 79.17,199.99 86.05,201.22 86.67,198.12 
			92.88,196.24 95.7,198.43 98.15,198.12 98.15,192.21 102.21,190.32 104.1,185.36 101.29,182.87 101.29,176.63 103.16,175.07 
			101.92,166.98 97.57,166.03 93.5,169.16 88.54,165.72 80.72,159.51 76.38,161.68 78.26,166.03 79.17,173.2 		"/>
							<polygon data-color={data[14]} fill="currentColor" points="108.45,146.42 104.72,148.91 109.08,155.76 122.78,151.73 129.95,152.96 134.63,147.99 
			141.8,150.17 143.65,147.99 140.85,132.41 132.74,131.77 130.87,135.82 124.04,137.7 118.42,134.28 101.29,131.17 100.99,133.65 
			107.22,138.64"/>
							<polygon data-color={data[61]} fill="currentColor" points="111.89,169.47 115,170.72 123.41,165.09 127.13,165.09 127.13,169.47 129.95,172.27 
			135.55,172.27 135.55,169.76 141.8,171.34 141.47,175.4 153.6,180.98 152.36,174.76 158.28,171.66 158.28,167.28 153.31,162.63 
			151.12,154.83 147.39,154.83 147.39,152.65 141.8,150.17 134.63,147.99 129.95,152.96 122.78,151.73 109.08,155.76 111.59,161.68 
			107.22,166.98 109.7,170.72"/>
							<polygon data-color={data[50]} fill="currentColor" points="97.57,166.03 101.92,166.98 107.22,166.98 111.59,161.68 109.08,155.76 104.72,148.91 
			108.45,146.42 107.22,138.64 100.99,133.65 97.87,133.33 96,124.32 98.5,121.2 97.26,118.7 91.64,116.52 89.78,120.58 
			84.16,118.7 82.29,116.52 79.8,116.52 81.98,131.17 89.15,138.95 86.05,156.7 91.01,161.34 88.54,165.72 93.5,169.16 		"/>
							<polygon data-color={data[27]} fill="currentColor" points="141.8,150.17 147.39,152.65 147.39,154.83 151.12,154.83 153.31,162.63 156.09,157.94 
			167.6,156.38 172.91,150.78 171.98,146.42 175.1,145.17 177.29,140.19 181.02,137.7 178.2,131.17 171.52,128.82 160.47,136.61 
			150.79,131.17 141.47,130.53 140.85,132.41 143.65,147.99 		"/>
							<polygon data-color={data[76]} fill="currentColor" points="160.47,136.61 171.52,128.82 178.2,131.17 180.39,127.73 177.29,122.14 178.55,114.48 
			178.83,112.79 173.56,104.38 168.18,103.79 164.2,109.05 154.54,113.42 144.57,113.72 133.68,121.2 133.97,127.42 141.47,130.53 
			150.79,131.17 		"/>
							<polygon data-color={data[67]} fill="currentColor" points="322.09,145.48 319.27,147.68 319.27,150.94 328.63,157.49 328.45,162.91 323.95,166.03 
			325.98,175.99 336.71,181.92 343.9,160.12 352.9,147.99 345.44,142.66 337.66,143.61 332.2,148.44 		"/>
							<polygon data-color={data[68]} fill="currentColor" points="318.52,193.59 323.32,197.19 325.51,202.79 324.91,208.08 326.77,207.17 331.11,210.26 
			333.92,210.57 338.9,203.72 337.03,198.71 336.71,181.92 325.98,175.99 		"/>
							<polygon data-color={data[10]} fill="currentColor" points="229.9,184.1 232.39,184.1 238.95,191.88 243.3,190.32 247.04,192.52 254.82,192.52 254.82,189.1 
			259.51,189.1 259.51,185.36 263.52,182.87 263.52,175.4 259.18,172.58 258.25,167.28 249.53,165.09 246.73,159.18 239.88,161.34 
			232.39,167.28 226.18,165.09 223.42,168.87 223.35,173.2 229.6,179.12 		"/>
							<polygon data-color={data[52]} fill="currentColor" points="258.25,167.28 259.18,172.58 263.52,175.4 263.52,182.87 259.51,185.36 259.51,189.1 
			262.28,189.1 262.91,192.82 266.95,196.24 265.1,198.43 266.34,201.53 273.2,201.53 274.75,204.04 280.36,204.35 280.36,201.84 
			286.9,201.84 286.9,197.19 291.27,193.14 291.27,189.7 287.5,185.03 285.03,185.03 287.21,180.04 281.59,173.53 277.85,173.53 
			276.94,169.16 264.78,159.82 261.65,161.68"/>
							<polygon data-color={data[51]} fill="currentColor" points="225.54,160.43 226.18,165.09 232.39,167.28 239.88,161.34 246.73,159.18 249.53,165.09 
			258.25,167.28 261.65,161.68 264.78,159.82 265.73,157.94 262.91,155.45 264.15,148.6 267.29,148.6 263.52,140.83 263.52,136.76 
			257.31,137.55 252.93,134.59 249.22,134.59 246.24,131.17 241.12,130.53 239.88,133.26 234.81,131.31 230.29,133.75 
			230.29,138.09 232.1,139.96 229.66,142.28 231.93,145.56 225.33,154.39 222.44,158.13 		"/>
							<polygon data-color={data[8]} fill="currentColor" points="245.17,116.52 239.25,123.99 241.12,130.53 246.24,131.17 249.22,134.59 252.93,134.59 
			257.31,137.55 263.52,136.76 266.65,136.76 269.46,129.3 266.95,126.8 266.95,123.99 271.95,124.62 277.25,123.69 266.34,114.35 
			261.07,113.42 261.07,103.46 257.64,100.95 253.26,103.75 252.93,109.05 243.61,109.98 243.92,114.35 		"/>
							<polygon data-color={data[62]} fill="currentColor" points="181.31,91.61 189.09,97.84 199.07,97.84 197.51,102.19 206.85,102.19 206.85,104.07 
			214.94,104.38 217.13,105.93 217.28,101.89 216.05,99.23 218.37,95.97 211.84,85.38 208.58,84.6 205.45,79.77 198.16,78.53 
			192.99,71.37 189.72,65.14 179.44,68.26 173.56,72 172.51,91.28 178.83,93.16 		"/>
							<polygon data-color={data[59]} fill="currentColor" points="198.16,78.53 205.45,79.77 208.58,84.6 211.84,85.38 218.37,95.97 216.05,99.23 217.28,101.89 
			217.13,105.93 228.97,104.38 240.82,106.87 242.69,95.35 238.95,92.85 230.55,94.1 229.9,87.88 224.59,83.21 222.12,87.57 
			218.37,83.83 218.37,77.6 216.2,73.54 207.78,77.91 202.51,72 200.94,61.72 189.72,65.14 192.99,71.37 		"/>
							<polygon data-color={data[60]} fill="currentColor" points="189.42,139.56 200.94,142.66 207.14,144.96 212.47,144.62 216.32,142.76 213.24,134.59 
			218.71,125.86 215.74,122.61 216.85,117.92 207.95,119.95 204.22,124.32 199.23,121.2 185.85,117.6 184.13,120.1 178.55,114.48 
			177.29,122.14 180.39,127.73 178.2,131.17 181.02,137.7 177.29,140.19 186.32,139.56 		"/>
							<polygon data-color={data[80]} fill="currentColor" points="178.83,112.79 178.55,114.48 184.13,120.1 185.85,117.6 199.23,121.2 204.22,124.32 
			207.95,119.95 216.85,117.92 215.41,113.25 217.13,107.8 217.13,105.93 214.94,104.38 206.85,104.07 206.85,102.19 197.51,102.19 
			199.07,97.84 189.09,97.84 181.31,91.61 178.83,93.16 172.51,91.28 172.3,95.35 174.8,98.47 171.06,100.01 168.18,103.79 
			173.56,104.38"/>
							<polygon data-color={data[2]} fill="currentColor" points="217.13,105.93 217.13,107.8 215.41,113.25 216.85,117.92 215.74,122.61 218.71,125.86 
			213.24,134.59 216.32,142.76 225.33,154.39 231.93,145.56 229.66,142.28 232.1,139.96 230.29,138.09 230.29,133.75 234.81,131.31 
			239.88,133.26 241.12,130.53 239.25,123.99 245.17,116.52 243.92,114.35 243.61,109.98 240.82,106.87 228.97,104.38 		"/>
							<polygon data-color={data[88]} fill="currentColor" points="287.21,180.04 285.03,185.03 287.5,185.03 291.27,189.7 296.54,187.23 302.46,190.66 
			305.88,189.4 309.32,191.57 313.04,189.7 318.52,193.59 325.98,175.99 323.95,166.03 317.1,170.56 310.57,167.46 309.47,170.07 
			301.53,169.3 294.36,172.89 290.79,165.27 287.84,165.27 280.51,170.56 277.85,173.53 281.59,173.53 		"/>
							<polygon data-color={data[57]} fill="currentColor" points="292.32,142.21 293.75,149.37 301.86,149.37 302.3,154.06 311.02,156.7 315.09,160.9 
			321.93,162.14 323.95,166.03 328.45,162.91 328.63,157.49 319.27,150.94 319.27,147.68 322.09,145.48 332.2,148.44 337.66,143.61 
			328.63,137.7 325.82,139.88 309.95,137.7 306.51,128.67 294.68,125.86 294.06,128.05 290.64,128.05 293.59,136.15 		"/>
							<polygon data-color={data[54]} fill="currentColor" points="283.16,129.15 283.77,133.33 289.24,141.59 288.61,147.83 286.59,151.09 287.84,154.06 
			286.1,156.7 287.84,165.27 290.79,165.27 294.36,172.89 301.53,169.3 309.47,170.07 310.57,167.46 317.1,170.56 323.95,166.03 
			321.93,162.14 315.09,160.9 311.02,156.7 302.3,154.06 301.86,149.37 293.75,149.37 292.32,142.21 293.59,136.15 290.64,128.05 
			284.1,123.07 277.25,123.69 278.34,130.69"/>
							<polygon data-color={data[55]} fill="currentColor" points="266.95,123.99 266.95,126.8 269.46,129.3 266.65,136.76 263.52,136.76 263.52,140.83 
			267.29,148.6 264.15,148.6 262.91,155.45 265.73,157.94 264.78,159.82 276.94,169.16 277.85,173.53 280.51,170.56 287.84,165.27 
			286.1,156.7 287.84,154.06 286.59,151.09 288.61,147.83 289.24,141.59 283.77,133.33 283.16,129.15 278.34,130.69 277.25,123.69 
			271.95,124.62 		"/>
							<polygon data-color={data[78]} fill="currentColor" points="175.72,163.25 178.2,163.25 180.39,170.07 183.5,170.07 188.33,166.67 188.33,161.54 
			190.88,158.82 192.08,151.68 187.75,151.99 177.45,149.54 175.1,145.17 171.98,146.42 172.91,150.78 175.72,155.45 		"/>
							<polygon data-color={data[95]} fill="currentColor" points="187.75,151.99 192.08,151.68 194.5,150.15 198.49,148.33 202.52,148.4 200.94,142.66 
			189.42,139.56 186.32,139.56 177.29,140.19 175.1,145.17 177.45,149.54"/>



							<line fill="currentColor" x1="194.5" y1="150.15" x2="197.26" y2="148.89" />
							<line fill="currentColor" x1="198.49" y1="148.33" x2="197.26" y2="148.89" />
							<polyline data-color={data[78]} fill="currentColor" points="175.1,145.17 177.45,149.54 187.75,151.99 192.08,151.68 		" />
							<line fill="currentColor" x1="198.49" y1="148.33" x2="197.26" y2="148.89" />
							<line fill="currentColor" x1="198.49" y1="148.33" x2="202.52" y2="148.4" />
							<line fill="currentColor" x1="199.98" y1="155.23" x2="204.64" y2="155.14" />
							<line fill="currentColor" x1="197.26" y1="148.89" x2="194.5" y2="150.15" />
							<line fill="currentColor" x1="192.08" y1="151.68" x2="190.88" y2="158.82" />
							<line fill="currentColor" x1="192.08" y1="151.68" x2="194.5" y2="150.15" />
							<line fill="currentColor" x1="197.19" y1="160.97" x2="197.44" y2="157.15" />
							<line fill="currentColor" x1="197.26" y1="148.89" x2="197.26" y2="148.89" />
							<line fill="currentColor" x1="197.76" y1="153.03" x2="197.26" y2="148.89" />
							<polyline fill="currentColor" points="199.98,155.23 199.98,156.24 197.44,157.15 		" />
							<polyline fill="currentColor" points="197.76,153.03 199.98,153.99 199.98,155.23 		" />
							<line fill="currentColor" x1="197.19" y1="160.97" x2="202.4" y2="161.53" />
							<polyline fill="currentColor" points="190.88,158.82 195.39,160.94 197.19,160.97 		" />
							<line fill="currentColor" x1="200.94" y1="142.66" x2="202.52" y2="148.4" />
							<line fill="currentColor" x1="202.4" y1="161.53" x2="204.64" y2="155.14" />
							<line fill="currentColor" x1="202.52" y1="148.4" x2="204.64" y2="155.14" />




							<polygon data-color={data[39]} fill="currentColor" points="303.71,237.52 295.13,227.86 290.48,227.86 292.32,222.88 290.48,218.69 283.48,217.73 
			275.99,232.37 280.67,234.56 280.04,240.77 283.48,243.27 279.13,253.24 284.72,260.39 288.45,256.66 290.94,256.97 
			291.56,259.46 294.68,259.46 299.56,254.78 299.65,251.05 302.46,247.32 302.46,244.82 299.83,242.03 		"/>
							<polygon data-color={data[70]} fill="currentColor" points="309.32,191.57 305.88,189.4 302.46,190.66 296.54,187.23 291.27,189.7 291.27,193.14 
			286.9,197.19 286.9,201.84 280.36,201.84 280.36,204.35 282.55,206.22 279.72,210.9 283.48,217.73 290.48,218.69 307.92,205.91 
			311.34,208.08 313.21,205.28 318.8,205.6 317.28,197.79 318.52,193.59 313.04,189.7 		"/>
							<polygon data-color={data[90]} fill="currentColor" points="323.32,197.19 318.52,193.59 317.28,197.79 318.8,205.6 319.44,209.59 322.75,211.16 
			324.91,208.08 325.51,202.79"/>
							<polygon data-color={data[25]} fill="currentColor" points="318.8,205.6 313.21,205.28 311.34,208.08 307.92,205.91 290.48,218.69 292.32,222.88 
			290.48,227.86 295.13,227.86 303.71,237.52 299.83,242.03 302.46,244.82 310.57,236.1 310.88,231.76 324.56,214 322.75,211.16 
			319.44,209.59"/>
							<polygon data-color={data[89]} fill="currentColor" points="214.02,193.14 214.02,199.35 209.98,200.92 211.84,207.75 218.71,207.93 223.21,211.82 
			228.19,209.34 240.82,218.05 244.38,216.81 245.01,208.08 251.55,197.65 247.04,192.52 243.3,190.32 238.95,191.88 232.39,184.1 
			229.9,184.1 229.6,179.12 223.35,173.2 213.71,176.94 215.28,179.45 213.08,182.87 216.5,191.28 		"/>
							<polygon data-color={data[58]} fill="currentColor" points="228.19,209.34 223.21,211.82 218.71,207.93 211.84,207.75 209.67,210.57 210.29,217.73 
			215.28,224.9 215.28,240.77 218.71,244.82 222.44,243.27 227.41,245.76 230.84,242.67 237.23,243.75 243.46,239.85 243.77,232.83 
			241.28,231.43 244.22,225.05 246.24,226.13 248.75,224.11 244.38,216.81 240.82,218.05 		"/>
							<polygon data-color={data[71]} fill="currentColor" points="261.82,233.45 248.75,224.11 246.24,226.13 244.22,225.05 241.28,231.43 243.77,232.83 
			243.46,239.85 237.23,243.75 230.84,242.67 234.27,250.12 240.82,253.85 242.35,258.84 244.22,263.51 253.26,264.77 
			252.93,260.09 256.36,261.33 259.79,259.17 263.86,262.57 269.16,251.98 273.51,250.74 279.13,253.24 283.48,243.27 
			280.04,240.77 280.67,234.56 275.99,232.37 271.33,230.2"/>
							<polygon data-color={data[37]} fill="currentColor" points="139.9,234.56 142.71,234.56 146.14,231.76 155.79,243.27 159.68,240.16 163.12,228.79 
			166.06,230.2 168.72,228.03 168.72,223.96 166.06,219.14 161.87,219.6 161.7,209.34 147.68,203.72 144.25,205.91 142.38,204.04 
			136.49,206.22 136.49,211.21 132.74,220.23 132.74,223.66 139.9,229.87"/>
							<polygon data-color={data[36]} fill="currentColor" points="178.37,257.89 179.29,254.95 184.45,256.04 186.32,255.1 187.7,256.21 190.99,256.28 
			191.29,246.07 187.86,239.85 189.89,238.29 186.95,226.77 181.02,226.92 185.37,221.64 174.63,221.01 168.72,223.96 
			168.72,228.03 166.06,230.2 163.12,228.79 159.68,240.16 155.79,243.27 155.79,248.88 158.28,251.38 161.1,251.38 163.57,256.66 
			169.67,258.04 170.74,256.51"/>
							<polygon data-color={data[18]} fill="currentColor" points="186.95,226.77 189.89,238.29 187.86,239.85 191.29,246.07 190.99,256.28 193.16,256.36 
			196.6,251.69 201.87,252.3 201.87,247.32 206.25,242.94 208.72,242.94 210.59,240.77 215.28,240.77 215.28,224.9 210.29,217.73 
			209.67,210.57 201.09,209.95 191.12,203.55 191.91,209.62 193.92,214.92 189.42,215.69 191.12,219.45 185.37,221.64 
			181.02,226.92 		"/>
							<polygon data-color={data[41]} fill="currentColor" points="154.23,192.21 147.68,203.72 161.7,209.34 161.87,219.6 166.06,219.14 168.72,223.96 
			174.63,221.01 185.37,221.64 191.12,219.45 189.42,215.69 193.92,214.92 191.91,209.62 191.12,203.55 180.56,203.72 174.8,197.65 
			173.85,189.86 167.18,192.21 164.83,189.4 156.74,185.36 153.31,187.54 		"/>
							<polygon data-color={data[45]} fill="currentColor" points="180.56,203.72 191.12,203.55 201.09,209.95 209.67,210.57 211.84,207.75 209.98,200.92 
			214.02,199.35 214.02,193.14 216.5,191.28 213.08,182.87 208.11,182.87 202.81,185.36 199.69,183.16 200.94,179.76 195.99,177.64 
			190.67,175.4 186.64,177.58 183.66,184.25 181.31,187.99 173.85,189.86 174.8,197.65 		"/>
							<polygon data-color={data[28]} fill="currentColor" points="158.28,171.66 152.36,174.76 153.6,180.98 156.74,185.36 164.83,189.4 167.18,192.21 
			173.85,189.86 181.31,187.99 183.66,184.25 186.64,177.58 183.5,170.07 180.39,170.07 178.2,163.25 175.72,163.25 175.72,155.45 
			172.91,150.78 167.6,156.38 156.09,157.94 153.31,162.63 158.28,167.28 		"/>
							<polygon data-color={data[21]} fill="currentColor" points="282.55,206.22 280.36,204.35 274.75,204.04 273.2,201.53 266.34,201.53 265.1,198.43 
			266.95,196.24 262.91,192.82 262.28,189.1 259.51,189.1 254.82,189.1 254.82,192.52 247.04,192.52 251.55,197.65 245.01,208.08 
			244.38,216.81 248.75,224.11 261.82,233.45 271.33,230.2 275.99,232.37 283.48,217.73 279.72,210.9 		"/>
							<polygon data-color={data[46]} fill="currentColor" points="189.09,319.88 189.09,316.78 180.07,316.78 175.43,313.35 169.19,313.35 169.19,320.83 
			164.5,327.97 161.1,328.29 158.11,332.92 156.09,336.06 158.28,342.31 166.39,347.92 170.42,345.1 173.56,346.99 176.66,343.86 
			181.94,343.86 184.13,341.67 182.58,338.88 187.86,335.13 191.91,331.71 190.99,321.75 		"/>
							<polygon data-color={data[75]} fill="currentColor" points="202.52,148.4 198.49,148.33 197.26,148.89 194.5,150.15 192.08,151.68 190.88,158.82 
			195.39,160.94 197.19,160.97 202.4,161.53 204.64,155.14 		"/>
							<polygon data-color={data[91]} fill="currentColor" points="195.99,177.64 201.87,173.2 200.61,166.98 202.4,161.53 197.19,160.97 195.39,160.94 
			190.88,158.82 188.33,161.54 188.33,166.67 183.5,170.07 186.64,177.58 190.67,175.4 		"/>
						</g>
						<g className="frontieres">

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			207.14,144.96 200.94,142.66 202.52,148.4 204.64,155.14 202.4,161.53 200.61,166.98 201.87,173.2 195.99,177.64 200.94,179.76 
			199.69,183.16 202.81,185.36 208.11,182.87 213.08,182.87 215.28,179.45 213.71,176.94 223.35,173.2 223.42,168.87 226.18,165.09 
			225.54,160.43 222.44,158.13 225.33,154.39 216.32,142.76 212.47,144.62 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			134.29,357.88 142.38,354.43 145.49,356 151.74,352.26 154.23,346.99 152.36,344.78 153.6,342.62 158.28,342.31 156.09,336.06 
			158.11,332.92 151.35,328.46 137.73,328.76 132.48,325.63 127.3,335.6 122.92,348.83 131.2,351.17 127.91,358.1 132.12,356.32 		
			"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			102.21,338.09 108.28,337.62 117.48,345.42 116.23,348.83 122.92,348.83 127.3,335.6 132.48,325.63 128.08,322.97 128.08,310.85 
			117.48,307.11 111.89,300.25 105.93,299.67 107.22,308.67 111.59,317.37 106.59,311.78 103.16,300.59 96,292.78 94.13,294.66 
			90.1,329.53 93.83,326.41 93.83,330.78 91.01,333.57 90.35,337.48 98.96,335.48 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			94.9,378.11 100.05,374.68 117.48,375.78 117.48,372.17 117.48,369.07 119.98,368.15 119.98,360.65 124.04,359.74 127.91,358.1 
			131.2,351.17 122.92,348.83 116.23,348.83 117.48,345.42 108.28,337.62 102.21,338.09 98.96,335.48 90.35,337.48 85.72,364.11 
			81.98,370.95 81.98,373.76 80.99,374.89 92.43,374.91 		"/>
							<polygon fill="currentColor" points="115.77,397.43 119.98,395.87 124.65,387.46 123.09,385.29 123.41,374.99 117.48,372.17 
			117.48,375.78 100.05,374.68 94.9,378.11 92.43,374.91 80.99,374.89 73.58,383.1 78.26,386.83 81.69,385.89 83.53,389.63 
			81.69,394.63 89.45,396.18 93.83,400.22 103.16,401.16 107.22,407.39 115.72,404.89 115.72,404.89 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			151.35,328.46 158.11,332.92 161.1,328.29 164.5,327.97 169.19,320.83 169.19,313.35 169.19,310.85 165.44,309.62 164.2,298.08 
			158.91,293.73 157.67,289.98 151.74,289.98 151.12,285.93 145.49,285.61 142.71,287.82 141.14,294.03 135.55,297.76 
			134.63,303.38 128.38,307.44 128.08,310.85 128.08,322.97 132.48,325.63 137.73,328.76 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			222.44,243.27 218.71,244.82 215.28,240.77 210.59,240.77 208.72,242.94 206.25,242.94 201.87,247.32 201.87,252.3 196.6,251.69 
			193.16,256.36 200.61,264.14 201.72,269.46 211.54,263.22 215.58,269.13 225.88,269.74 234.27,275.35 237.39,272.53 
			238.01,263.51 242.35,258.84 240.82,253.85 234.27,250.12 230.84,242.67 227.41,245.76 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			215.58,269.13 211.54,263.22 201.72,269.46 202.81,274.73 197.18,280.03 200.61,284.37 199.69,285.93 199.69,290.6 201.26,292.16 
			201.26,296.54 218.08,301.51 222.44,297.13 240.49,298.38 242.98,295.25 234.57,282.2 236.14,277.53 234.27,275.35 225.88,269.74 
					"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			222.44,305.25 218.08,301.51 201.26,296.54 195.34,299.33 195.34,302.76 191.59,306.81 191.59,310.85 189.09,313.04 
			189.09,316.78 189.09,319.88 190.99,321.75 191.91,331.71 200,331.71 208.4,319.24 211.84,321.75 214.02,327.97 218.08,320.83 
			220.25,320.83 223.99,317.37 224.92,310.22 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			252.93,298.38 240.49,298.38 222.44,297.13 218.08,301.51 222.44,305.25 224.92,310.22 223.99,317.37 226.18,317.7 228.36,321.44 
			235.82,321.44 241.44,326.12 244.22,318.94 248.91,318.94 254.5,312.72 254.5,307.72 257.31,307.72 257.31,303.99 253.9,302.43 		
			"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			134.63,270.08 134.92,266.31 128.38,256.66 130.87,248.27 128.38,237.98 130.25,233.61 127.46,227.07 116.86,227.07 
			111.89,231.13 105.35,229.87 111.89,244.82 110.94,253.56 115.61,258.23 108.75,258.23 110.01,263.82 121.22,268.18 
			126.51,274.09 129.33,269.13 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			154.85,261.63 158.28,261.63 163.57,256.66 161.1,251.38 158.28,251.38 155.79,248.88 155.79,243.27 146.14,231.76 142.71,234.56 
			139.9,234.56 139.9,229.87 132.74,223.66 127.46,227.07 130.25,233.61 128.38,237.98 130.87,248.27 128.38,256.66 134.92,266.31 
			134.63,270.08 140.54,271.61 143.65,268.5 145.81,270.08 151.9,266.01 151.9,264.6 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			117.48,307.11 128.08,310.85 128.38,307.44 122.92,301.19 118.73,300.25 121.07,293.57 116.53,282.2 124.04,282.04 126.51,274.09 
			121.22,268.18 110.01,263.82 108.75,258.23 102.84,258.23 97.57,260.39 93.19,267.25 98.15,273.48 95.37,279.09 96.94,282.51 
			92.24,282.82 92.24,286.56 99.1,289.98 105.64,297.47 105.93,299.67 111.89,300.25 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			135.55,297.76 141.14,294.03 142.71,287.82 145.49,285.61 151.74,277.53 156.09,275.66 156.09,272.23 151.9,272.23 151.9,266.01 
			145.81,270.08 143.65,268.5 140.54,271.61 134.63,270.08 129.33,269.13 126.51,274.09 124.04,282.04 116.53,282.2 121.07,293.57 
			118.73,300.25 122.92,301.19 128.38,307.44 134.63,303.38 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			218.93,336.24 214.02,327.97 211.84,321.75 208.4,319.24 200,331.71 191.91,331.71 187.86,335.13 182.58,338.88 184.13,341.67 
			181.94,343.86 185.69,346.04 186.32,351.65 190.99,350.08 200.61,354.74 204.99,366.59 210.59,366.29 213.17,371.58 
			218.01,367.45 218.45,364.42 223.35,364.42 223.35,362.61 227.26,360.06 227.26,355.46 223.67,353.27 226.34,349.93 
			219.55,346.36 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			204.99,366.59 200.61,354.74 190.99,350.08 186.32,351.65 184.13,353.51 180.07,353.82 173.56,360.98 173.85,364.42 
			177.58,370.02 177.29,373.44 178.83,373.44 185.99,383.45 190.44,384.35 193.62,380.28 202.96,382 205.62,372.29 208.63,374.07 
			213.17,371.58 210.59,366.29 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			155.49,399.92 157.33,392.74 165.14,394.93 166.06,389.32 176.18,389.01 181.19,382.46 185.99,383.45 178.83,373.44 
			177.29,373.44 177.58,370.02 173.85,364.42 173.56,360.98 165.75,362.55 164.2,367.22 159.83,365.03 154.85,365.98 162.63,374.07 
			156.09,377.18 155.79,385.29 151.74,383.1 147.68,385.89 139.6,391.52 143.33,398.67 139.6,403.96 139.37,410.89 144.89,411.74 
			143.65,407.08 145.49,405.22 151.45,407.03 151.12,403.04 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			143.33,398.67 139.6,391.52 147.68,385.89 134.63,382.79 130.87,384.01 131.2,379.99 126.51,374.07 123.41,374.99 123.09,385.29 
			124.65,387.46 119.98,395.87 115.77,397.43 115.72,404.89 115.72,404.89 121.22,411.13 127.13,412.37 129,411.13 132.42,412.06 
			136.79,410.49 139.37,410.89 139.6,403.96 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			145.49,356 142.38,354.43 134.29,357.88 132.12,356.32 127.91,358.1 124.04,359.74 119.98,360.65 119.98,368.15 117.48,369.07 
			117.48,372.17 123.41,374.99 126.51,374.07 131.2,379.99 130.87,384.01 134.63,382.79 147.68,385.89 151.74,383.1 155.79,385.29 
			156.09,377.18 162.63,374.07 154.85,365.98 152.36,361.61 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			184.13,353.51 186.32,351.65 185.69,346.04 181.94,343.86 176.66,343.86 173.56,346.99 170.42,345.1 166.39,347.92 158.28,342.31 
			153.6,342.62 152.36,344.78 154.23,346.99 151.74,352.26 145.49,356 152.36,361.61 154.85,365.98 159.83,365.03 164.2,367.22 
			165.75,362.55 173.56,360.98 180.07,353.82 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			190.73,413.23 189.02,409.1 184.03,409.87 181.71,404.99 184.68,404.58 184.28,401.24 185.69,400.3 183.5,393.85 176.18,389.01 
			166.06,389.32 165.14,394.93 157.33,392.74 155.49,399.92 151.12,403.04 151.45,407.03 166.69,411.74 169.79,415.48 
			174.48,415.78 176.97,419.52 184.82,414.79 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			207.48,406.45 196.92,406.15 196.92,411.74 190.73,413.23 184.82,414.79 176.97,419.52 187.23,425.76 194.73,423.9 204.69,427.64 
			207.16,423.9 216.2,422.02 220.55,424.21 220.55,419.83 217.76,417.97 217.47,405.53 212.16,403.04 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			209.03,389.32 202.81,385.58 202.96,382 193.62,380.28 190.44,384.35 185.99,383.45 181.19,382.46 176.18,389.01 183.5,393.85 
			185.69,400.3 184.28,401.24 184.68,404.58 181.71,404.99 184.03,409.87 189.02,409.1 190.73,413.23 196.92,411.74 196.92,406.15 
			207.48,406.45 212.16,403.04 217.47,405.53 217.43,403.66 220.87,399.92 219.3,395.87 224.44,391.3 211.54,385.29 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			238.01,358.81 232.39,362.21 227.26,360.06 223.35,362.61 223.35,364.42 218.45,364.42 218.01,367.45 213.17,371.58 
			208.63,374.07 205.62,372.29 202.96,382 202.81,385.58 209.03,389.32 211.54,385.29 224.44,391.3 227.73,388.39 229.28,389.32 
			233.95,382.79 237.06,384.65 247.04,377.18 249.53,368.47 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			262.28,364.42 266.91,360.31 270.71,356.94 264.47,345.73 255.13,341.67 252.34,345.1 247.66,342 246.41,336.7 242.03,340.44 
			244.22,348.83 239.25,351.96 233.95,349.47 226.34,349.93 223.67,353.27 227.26,355.46 227.26,360.06 232.39,362.21 
			238.01,358.81 249.53,368.47 247.04,377.18 249.53,380.91 252.34,380.75 261.36,371.25 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			241.44,326.12 235.82,321.44 228.36,321.44 226.18,317.7 223.99,317.37 220.25,320.83 218.08,320.83 214.02,327.97 218.93,336.24 
			219.55,346.36 226.34,349.93 233.95,349.47 239.25,351.96 244.22,348.83 242.03,340.44 246.41,336.7 241.75,331.4 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			320.22,333.57 311.81,332.35 304.32,335.48 298.73,342 301.21,346.65 294.06,346.04 287.84,350.4 292.19,353.51 293.11,361.61 
			298.1,367.22 303.39,365.67 305.88,367.52 310.57,363.16 314.31,364.42 317.73,361.29 322.7,361.29 325.19,357.56 330.5,356.94 
			329.55,353.2 326.77,352.88 322.7,344.17 327.38,339.49 330.23,327.29 326.44,325.48 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			313.04,308.98 309.32,306.81 307.75,310.85 311.49,315.23 311.49,319.24 307.13,317.7 297.78,321.75 294.99,324.54 292.19,327.35 
			289.69,334.83 285.64,334.53 286.9,340.44 291.56,341.67 294.06,346.04 301.21,346.65 298.73,342 304.32,335.48 311.81,332.35 
			320.22,333.57 326.44,325.48 330.23,327.29 331.11,323.62 320.22,307.72 315.26,307.44 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			338.58,366.91 349.19,362.85 349.19,356.94 347.01,355.38 352.28,345.73 348.26,342.91 342.95,346.36 327.38,339.49 322.7,344.17 
			326.77,352.88 329.55,353.2 330.5,356.94 325.19,357.56 322.7,361.29 326.14,367.22 332.98,374.37 338.58,371.25 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			322.7,361.29 317.73,361.29 314.31,364.42 310.57,363.16 305.88,367.52 303.39,365.67 298.1,367.22 294.68,375.31 296.87,379.67 
			294.06,383.1 297.48,385.89 293.75,390.57 302.46,395.56 304.03,392.14 307.75,393.98 311.49,390.87 315.55,392.74 324.91,388.06 
			324.91,383.1 332.68,378.11 332.98,374.37 326.14,367.22 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			292.19,353.51 287.84,350.4 276.94,346.04 276.94,342.91 274.12,343.22 275.04,338.57 272.56,336.7 270.39,340.13 271.33,343.53 
			269.76,344.49 266.34,342.91 264.47,345.73 270.71,356.94 266.91,360.31 277.85,366.29 285.35,366.59 292.49,370.02 298.1,367.22 
			293.11,361.61 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			294.06,383.1 296.87,379.67 294.68,375.31 298.1,367.22 292.49,370.02 285.35,366.59 277.85,366.29 266.91,360.31 262.28,364.42 
			261.36,371.25 252.34,380.75 261.36,380.28 264.15,384.65 268.85,384.65 270.71,380.6 276.94,385.89 283.48,383.1 285.97,384.65 
			285.97,389.32 293.75,390.57 297.48,385.89 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			262.91,335.48 266.34,327.97 269.46,320.51 266.03,308.98 266.65,302.14 264.15,299.02 257.31,303.99 257.31,307.72 254.5,307.72 
			254.5,312.72 248.91,318.94 244.22,318.94 241.44,326.12 241.75,331.4 246.41,336.7 247.66,342 252.34,345.1 255.13,341.67 
			264.47,345.73 266.34,342.91 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			288.45,321.75 286.59,309.62 278.48,310.85 276.94,303.68 270.07,299.65 266.65,302.14 266.03,308.98 269.46,320.51 
			266.34,327.97 262.91,335.48 266.34,342.91 269.76,344.49 271.33,343.53 270.39,340.13 272.56,336.7 275.04,338.57 274.12,343.22 
			276.94,342.91 276.94,346.04 287.84,350.4 294.06,346.04 291.56,341.67 286.9,340.44 285.64,334.53 289.69,334.83 292.19,327.35 
			294.99,324.54 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			269.16,289.69 268.2,294.66 265.73,294.98 264.15,299.02 266.65,302.14 270.07,299.65 276.94,303.68 278.48,310.85 286.59,309.62 
			288.45,321.75 294.99,324.54 297.78,321.75 307.13,317.7 311.49,319.24 311.49,315.23 307.75,310.85 309.32,306.81 305.26,305.55 
			306.2,295.9 299.65,292.78 298.1,295.25 290.94,293.1 291.27,287.82 282.21,276.91 274.12,281.26 275.4,284.37 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			261.98,291.85 262.28,289.98 257.93,289.98 253.26,285.93 254.5,281.26 250.47,269.44 253.9,267.25 253.26,264.77 244.22,263.51 
			242.35,258.84 238.01,263.51 237.39,272.53 234.27,275.35 236.14,277.53 234.57,282.2 242.98,295.25 240.49,298.38 252.93,298.38 
			253.9,302.43 257.31,303.99 264.15,299.02 265.73,294.98 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			256.36,261.33 252.93,260.09 253.26,264.77 253.9,267.25 250.47,269.44 254.5,281.26 253.26,285.93 257.93,289.98 262.28,289.98 
			261.98,291.85 265.73,294.98 268.2,294.66 269.16,289.69 275.4,284.37 274.12,281.26 269.46,280.31 267.29,275.35 263.52,275.66 
			262.6,270.37 264.78,266.31 263.86,262.57 259.79,259.17 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			291.56,259.46 290.94,256.97 288.45,256.66 284.72,260.39 279.13,253.24 273.51,250.74 269.16,251.98 263.86,262.57 
			264.78,266.31 262.6,270.37 263.52,275.66 267.29,275.35 269.46,280.31 274.12,281.26 282.21,276.91 291.27,287.82 292.49,274.09 
			294.06,267.57 299.35,262.57 299.56,254.78 294.68,259.46 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			292.49,274.09 309.01,284.06 314.92,272.85 319.6,280.03 324.91,278.47 328.63,274.41 325.19,267.57 323.32,268.18 321.76,256.36 
			319.27,253.85 307.43,256.36 302.16,262.88 299.35,262.57 294.06,267.57 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			314.92,272.85 309.01,284.06 292.49,274.09 291.27,287.82 290.94,293.1 298.1,295.25 299.65,292.78 306.2,295.9 305.26,305.55 
			309.32,306.81 313.04,308.98 315.26,307.44 320.22,307.72 329.55,303.07 333.6,293.73 327.69,281.91 325.19,280.95 324.91,278.47 
			319.6,280.03 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			181.31,280.31 175.72,278.14 171.06,266.31 168.56,265.07 171.35,261.33 169.67,258.04 163.57,256.66 158.28,261.63 
			154.85,261.63 151.9,264.6 151.9,266.01 151.9,272.23 156.09,272.23 156.09,275.66 151.74,277.53 145.49,285.61 151.12,285.93 
			151.74,289.98 157.67,289.98 158.91,293.73 164.2,298.08 182.88,285.93 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			197.18,280.03 202.81,274.73 201.72,269.46 200.61,264.14 193.16,256.36 190.99,256.28 187.7,256.21 186.32,255.1 184.45,256.04 
			179.29,254.95 178.37,257.89 170.74,256.51 169.67,258.04 171.35,261.33 168.56,265.07 171.06,266.31 175.72,278.14 
			181.31,280.31 182.88,285.93 188.17,283.74 195.34,286.56 199.69,285.93 200.61,284.37 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			180.07,316.78 189.09,316.78 189.09,313.04 191.59,310.85 191.59,306.81 195.34,302.76 195.34,299.33 201.26,296.54 
			201.26,292.16 199.69,290.6 199.69,285.93 195.34,286.56 188.17,283.74 182.88,285.93 164.2,298.08 165.44,309.62 169.19,310.85 
			169.19,313.35 175.43,313.35 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			86.35,229.87 91.64,232.99 95.05,227.07 96.62,215.88 104.72,215.24 103.16,205.28 99.42,203.72 98.15,198.12 95.7,198.43 
			92.88,196.24 86.67,198.12 86.05,201.22 79.17,199.99 72.96,202.48 72.96,206.83 61.73,209.95 59.25,214 59.25,218.05 
			63.93,220.52 69.2,217.41 76.68,219.91 69.85,220.86 67.03,224.29 74.83,229.87 85.72,233.93 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			116.86,227.07 127.46,227.07 132.74,223.66 132.74,220.23 136.49,211.21 136.49,206.22 119.68,198.12 113.73,199.99 98.15,198.12 
			99.42,203.72 103.16,205.28 104.72,215.24 96.62,215.88 95.05,227.07 105.35,229.87 111.89,231.13 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			119.68,198.12 121.54,190.32 125.9,188.16 125.28,183.16 128.38,180.04 127.46,176.32 129.95,172.27 127.13,169.47 127.13,165.09 
			123.41,165.09 115,170.72 111.89,169.47 109.7,170.72 107.22,166.98 101.92,166.98 103.16,175.07 101.29,176.63 101.29,182.87 
			104.1,185.36 102.21,190.32 98.15,192.21 98.15,198.12 113.73,199.99 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			144.25,205.91 147.68,203.72 154.23,192.21 153.31,187.54 156.74,185.36 153.6,180.98 141.47,175.4 141.8,171.34 135.55,169.76 
			135.55,172.27 129.95,172.27 127.46,176.32 128.38,180.04 125.28,183.16 125.9,188.16 121.54,190.32 119.68,198.12 136.49,206.22 
			142.38,204.04 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			108.75,258.23 115.61,258.23 110.94,253.56 111.89,244.82 105.35,229.87 95.05,227.07 91.64,232.99 86.35,229.87 85.72,233.93 
			74.83,229.87 68.61,236.72 75.15,242.67 75.45,251.69 91.64,261.01 97.57,260.39 102.84,258.23 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			33.4,168.85 34.02,175.68 43.35,180.04 46.79,175.4 57.38,181.92 59.87,185.66 62.37,179.12 66.42,181.92 69.2,180.04 
			79.17,173.2 78.26,166.03 67.66,161.07 62.04,161.98 59.56,166.03 57.69,166.03 48.05,149.86 34.66,152.34 33.74,156.38 
			35.27,162.63 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			72.96,202.48 75.45,195.93 74.2,188.77 69.2,180.04 66.42,181.92 62.37,179.12 59.87,185.66 57.38,181.92 46.79,175.4 
			43.35,180.04 34.02,175.68 29.36,181.6 38.38,188.16 32.39,197.05 39.01,199.06 43.05,208.08 45.84,205.6 51.47,207.45 
			61.73,209.95 72.96,206.83 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			29.36,181.6 34.02,175.68 33.4,168.85 35.27,162.63 33.74,156.38 28.41,155.76 27.78,158.57 23.44,158.25 22.51,155.13 
			17.5,154.83 15.63,157.34 12.23,155.76 -0.23,163.55 1.32,168.85 13.16,169.47 14.1,173.84 5.7,172.27 5.7,175.99 11.62,176.32 
			11.29,180.04 2.58,180.36 2.26,184.72 5.38,184.1 9.72,189.7 9.11,193.76 14.71,195 14.4,191.28 24.7,194.68 32.39,197.05 
			38.38,188.16 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			69.2,180.04 74.2,188.77 75.45,195.93 72.96,202.48 79.17,199.99 86.05,201.22 86.67,198.12 92.88,196.24 95.7,198.43 
			98.15,198.12 98.15,192.21 102.21,190.32 104.1,185.36 101.29,182.87 101.29,176.63 103.16,175.07 101.92,166.98 97.57,166.03 
			93.5,169.16 88.54,165.72 80.72,159.51 76.38,161.68 78.26,166.03 79.17,173.2 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			108.45,146.42 104.72,148.91 109.08,155.76 122.78,151.73 129.95,152.96 134.63,147.99 141.8,150.17 143.65,147.99 140.85,132.41 
			132.74,131.77 130.87,135.82 124.04,137.7 118.42,134.28 101.29,131.17 100.99,133.65 107.22,138.64 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			111.89,169.47 115,170.72 123.41,165.09 127.13,165.09 127.13,169.47 129.95,172.27 135.55,172.27 135.55,169.76 141.8,171.34 
			141.47,175.4 153.6,180.98 152.36,174.76 158.28,171.66 158.28,167.28 153.31,162.63 151.12,154.83 147.39,154.83 147.39,152.65 
			141.8,150.17 134.63,147.99 129.95,152.96 122.78,151.73 109.08,155.76 111.59,161.68 107.22,166.98 109.7,170.72 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			97.57,166.03 101.92,166.98 107.22,166.98 111.59,161.68 109.08,155.76 104.72,148.91 108.45,146.42 107.22,138.64 100.99,133.65 
			97.87,133.33 96,124.32 98.5,121.2 97.26,118.7 91.64,116.52 89.78,120.58 84.16,118.7 82.29,116.52 79.8,116.52 81.98,131.17 
			89.15,138.95 86.05,156.7 91.01,161.34 88.54,165.72 93.5,169.16 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			141.8,150.17 147.39,152.65 147.39,154.83 151.12,154.83 153.31,162.63 156.09,157.94 167.6,156.38 172.91,150.78 171.98,146.42 
			175.1,145.17 177.29,140.19 181.02,137.7 178.2,131.17 171.52,128.82 160.47,136.61 150.79,131.17 141.47,130.53 140.85,132.41 
			143.65,147.99 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			160.47,136.61 171.52,128.82 178.2,131.17 180.39,127.73 177.29,122.14 178.55,114.48 178.83,112.79 173.56,104.38 168.18,103.79 
			164.2,109.05 154.54,113.42 144.57,113.72 133.68,121.2 133.97,127.42 141.47,130.53 150.79,131.17 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			322.09,145.48 319.27,147.68 319.27,150.94 328.63,157.49 328.45,162.91 323.95,166.03 325.98,175.99 336.71,181.92 343.9,160.12 
			352.9,147.99 345.44,142.66 337.66,143.61 332.2,148.44 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			318.52,193.59 323.32,197.19 325.51,202.79 324.91,208.08 326.77,207.17 331.11,210.26 333.92,210.57 338.9,203.72 337.03,198.71 
			336.71,181.92 325.98,175.99 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			229.9,184.1 232.39,184.1 238.95,191.88 243.3,190.32 247.04,192.52 254.82,192.52 254.82,189.1 259.51,189.1 259.51,185.36 
			263.52,182.87 263.52,175.4 259.18,172.58 258.25,167.28 249.53,165.09 246.73,159.18 239.88,161.34 232.39,167.28 226.18,165.09 
			223.42,168.87 223.35,173.2 229.6,179.12 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			258.25,167.28 259.18,172.58 263.52,175.4 263.52,182.87 259.51,185.36 259.51,189.1 262.28,189.1 262.91,192.82 266.95,196.24 
			265.1,198.43 266.34,201.53 273.2,201.53 274.75,204.04 280.36,204.35 280.36,201.84 286.9,201.84 286.9,197.19 291.27,193.14 
			291.27,189.7 287.5,185.03 285.03,185.03 287.21,180.04 281.59,173.53 277.85,173.53 276.94,169.16 264.78,159.82 261.65,161.68 
					"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			225.54,160.43 226.18,165.09 232.39,167.28 239.88,161.34 246.73,159.18 249.53,165.09 258.25,167.28 261.65,161.68 
			264.78,159.82 265.73,157.94 262.91,155.45 264.15,148.6 267.29,148.6 263.52,140.83 263.52,136.76 257.31,137.55 252.93,134.59 
			249.22,134.59 246.24,131.17 241.12,130.53 239.88,133.26 234.81,131.31 230.29,133.75 230.29,138.09 232.1,139.96 229.66,142.28 
			231.93,145.56 225.33,154.39 222.44,158.13 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			245.17,116.52 239.25,123.99 241.12,130.53 246.24,131.17 249.22,134.59 252.93,134.59 257.31,137.55 263.52,136.76 
			266.65,136.76 269.46,129.3 266.95,126.8 266.95,123.99 271.95,124.62 277.25,123.69 266.34,114.35 261.07,113.42 261.07,103.46 
			257.64,100.95 253.26,103.75 252.93,109.05 243.61,109.98 243.92,114.35 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			181.31,91.61 189.09,97.84 199.07,97.84 197.51,102.19 206.85,102.19 206.85,104.07 214.94,104.38 217.13,105.93 217.28,101.89 
			216.05,99.23 218.37,95.97 211.84,85.38 208.58,84.6 205.45,79.77 198.16,78.53 192.99,71.37 189.72,65.14 179.44,68.26 
			173.56,72 172.51,91.28 178.83,93.16 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			198.16,78.53 205.45,79.77 208.58,84.6 211.84,85.38 218.37,95.97 216.05,99.23 217.28,101.89 217.13,105.93 228.97,104.38 
			240.82,106.87 242.69,95.35 238.95,92.85 230.55,94.1 229.9,87.88 224.59,83.21 222.12,87.57 218.37,83.83 218.37,77.6 
			216.2,73.54 207.78,77.91 202.51,72 200.94,61.72 189.72,65.14 192.99,71.37 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			189.42,139.56 200.94,142.66 207.14,144.96 212.47,144.62 216.32,142.76 213.24,134.59 218.71,125.86 215.74,122.61 
			216.85,117.92 207.95,119.95 204.22,124.32 199.23,121.2 185.85,117.6 184.13,120.1 178.55,114.48 177.29,122.14 180.39,127.73 
			178.2,131.17 181.02,137.7 177.29,140.19 186.32,139.56 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			178.83,112.79 178.55,114.48 184.13,120.1 185.85,117.6 199.23,121.2 204.22,124.32 207.95,119.95 216.85,117.92 215.41,113.25 
			217.13,107.8 217.13,105.93 214.94,104.38 206.85,104.07 206.85,102.19 197.51,102.19 199.07,97.84 189.09,97.84 181.31,91.61 
			178.83,93.16 172.51,91.28 172.3,95.35 174.8,98.47 171.06,100.01 168.18,103.79 173.56,104.38 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			217.13,105.93 217.13,107.8 215.41,113.25 216.85,117.92 215.74,122.61 218.71,125.86 213.24,134.59 216.32,142.76 225.33,154.39 
			231.93,145.56 229.66,142.28 232.1,139.96 230.29,138.09 230.29,133.75 234.81,131.31 239.88,133.26 241.12,130.53 239.25,123.99 
			245.17,116.52 243.92,114.35 243.61,109.98 240.82,106.87 228.97,104.38 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			287.21,180.04 285.03,185.03 287.5,185.03 291.27,189.7 296.54,187.23 302.46,190.66 305.88,189.4 309.32,191.57 313.04,189.7 
			318.52,193.59 325.98,175.99 323.95,166.03 317.1,170.56 310.57,167.46 309.47,170.07 301.53,169.3 294.36,172.89 290.79,165.27 
			287.84,165.27 280.51,170.56 277.85,173.53 281.59,173.53 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			292.32,142.21 293.75,149.37 301.86,149.37 302.3,154.06 311.02,156.7 315.09,160.9 321.93,162.14 323.95,166.03 328.45,162.91 
			328.63,157.49 319.27,150.94 319.27,147.68 322.09,145.48 332.2,148.44 337.66,143.61 328.63,137.7 325.82,139.88 309.95,137.7 
			306.51,128.67 294.68,125.86 294.06,128.05 290.64,128.05 293.59,136.15 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			283.16,129.15 283.77,133.33 289.24,141.59 288.61,147.83 286.59,151.09 287.84,154.06 286.1,156.7 287.84,165.27 290.79,165.27 
			294.36,172.89 301.53,169.3 309.47,170.07 310.57,167.46 317.1,170.56 323.95,166.03 321.93,162.14 315.09,160.9 311.02,156.7 
			302.3,154.06 301.86,149.37 293.75,149.37 292.32,142.21 293.59,136.15 290.64,128.05 284.1,123.07 277.25,123.69 278.34,130.69 
					"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			266.95,123.99 266.95,126.8 269.46,129.3 266.65,136.76 263.52,136.76 263.52,140.83 267.29,148.6 264.15,148.6 262.91,155.45 
			265.73,157.94 264.78,159.82 276.94,169.16 277.85,173.53 280.51,170.56 287.84,165.27 286.1,156.7 287.84,154.06 286.59,151.09 
			288.61,147.83 289.24,141.59 283.77,133.33 283.16,129.15 278.34,130.69 277.25,123.69 271.95,124.62 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			175.72,163.25 178.2,163.25 180.39,170.07 183.5,170.07 188.33,166.67 188.33,161.54 190.88,158.82 192.08,151.68 187.75,151.99 
			177.45,149.54 175.1,145.17 171.98,146.42 172.91,150.78 175.72,155.45 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			187.75,151.99 192.08,151.68 194.5,150.15 198.49,148.33 202.52,148.4 200.94,142.66 189.42,139.56 186.32,139.56 177.29,140.19 
			175.1,145.17 177.45,149.54 		"/>

							<line fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="194.5" y1="150.15" x2="197.26" y2="148.89" />

							<line fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="198.49" y1="148.33" x2="197.26" y2="148.89" />

							<polyline fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			175.1,145.17 177.45,149.54 187.75,151.99 192.08,151.68 		"/>

							<line fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="198.49" y1="148.33" x2="197.26" y2="148.89" />

							<line fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="198.49" y1="148.33" x2="202.52" y2="148.4" />

							<line fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="197.26" y1="148.89" x2="194.5" y2="150.15" />

							<line fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="192.08" y1="151.68" x2="190.88" y2="158.82" />

							<line fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="192.08" y1="151.68" x2="194.5" y2="150.15" />

							<line fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="197.26" y1="148.89" x2="197.26" y2="148.89" />

							<line fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="197.19" y1="160.97" x2="202.4" y2="161.53" />

							<polyline fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			190.88,158.82 195.39,160.94 197.19,160.97 		"/>

							<line fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="200.94" y1="142.66" x2="202.52" y2="148.4" />

							<line fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="202.4" y1="161.53" x2="204.64" y2="155.14" />

							<line fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="202.52" y1="148.4" x2="204.64" y2="155.14" />

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			303.71,237.52 295.13,227.86 290.48,227.86 292.32,222.88 290.48,218.69 283.48,217.73 275.99,232.37 280.67,234.56 
			280.04,240.77 283.48,243.27 279.13,253.24 284.72,260.39 288.45,256.66 290.94,256.97 291.56,259.46 294.68,259.46 
			299.56,254.78 299.65,251.05 302.46,247.32 302.46,244.82 299.83,242.03 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			309.32,191.57 305.88,189.4 302.46,190.66 296.54,187.23 291.27,189.7 291.27,193.14 286.9,197.19 286.9,201.84 280.36,201.84 
			280.36,204.35 282.55,206.22 279.72,210.9 283.48,217.73 290.48,218.69 307.92,205.91 311.34,208.08 313.21,205.28 318.8,205.6 
			317.28,197.79 318.52,193.59 313.04,189.7 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			323.32,197.19 318.52,193.59 317.28,197.79 318.8,205.6 319.44,209.59 322.75,211.16 324.91,208.08 325.51,202.79 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			318.8,205.6 313.21,205.28 311.34,208.08 307.92,205.91 290.48,218.69 292.32,222.88 290.48,227.86 295.13,227.86 303.71,237.52 
			299.83,242.03 302.46,244.82 310.57,236.1 310.88,231.76 324.56,214 322.75,211.16 319.44,209.59 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			214.02,193.14 214.02,199.35 209.98,200.92 211.84,207.75 218.71,207.93 223.21,211.82 228.19,209.34 240.82,218.05 
			244.38,216.81 245.01,208.08 251.55,197.65 247.04,192.52 243.3,190.32 238.95,191.88 232.39,184.1 229.9,184.1 229.6,179.12 
			223.35,173.2 213.71,176.94 215.28,179.45 213.08,182.87 216.5,191.28 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			228.19,209.34 223.21,211.82 218.71,207.93 211.84,207.75 209.67,210.57 210.29,217.73 215.28,224.9 215.28,240.77 218.71,244.82 
			222.44,243.27 227.41,245.76 230.84,242.67 237.23,243.75 243.46,239.85 243.77,232.83 241.28,231.43 244.22,225.05 
			246.24,226.13 248.75,224.11 244.38,216.81 240.82,218.05 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			261.82,233.45 248.75,224.11 246.24,226.13 244.22,225.05 241.28,231.43 243.77,232.83 243.46,239.85 237.23,243.75 
			230.84,242.67 234.27,250.12 240.82,253.85 242.35,258.84 244.22,263.51 253.26,264.77 252.93,260.09 256.36,261.33 
			259.79,259.17 263.86,262.57 269.16,251.98 273.51,250.74 279.13,253.24 283.48,243.27 280.04,240.77 280.67,234.56 
			275.99,232.37 271.33,230.2 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			139.9,234.56 142.71,234.56 146.14,231.76 155.79,243.27 159.68,240.16 163.12,228.79 166.06,230.2 168.72,228.03 168.72,223.96 
			166.06,219.14 161.87,219.6 161.7,209.34 147.68,203.72 144.25,205.91 142.38,204.04 136.49,206.22 136.49,211.21 132.74,220.23 
			132.74,223.66 139.9,229.87 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			178.37,257.89 179.29,254.95 184.45,256.04 186.32,255.1 187.7,256.21 190.99,256.28 191.29,246.07 187.86,239.85 189.89,238.29 
			186.95,226.77 181.02,226.92 185.37,221.64 174.63,221.01 168.72,223.96 168.72,228.03 166.06,230.2 163.12,228.79 159.68,240.16 
			155.79,243.27 155.79,248.88 158.28,251.38 161.1,251.38 163.57,256.66 169.67,258.04 170.74,256.51 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			186.95,226.77 189.89,238.29 187.86,239.85 191.29,246.07 190.99,256.28 193.16,256.36 196.6,251.69 201.87,252.3 201.87,247.32 
			206.25,242.94 208.72,242.94 210.59,240.77 215.28,240.77 215.28,224.9 210.29,217.73 209.67,210.57 201.09,209.95 191.12,203.55 
			191.91,209.62 193.92,214.92 189.42,215.69 191.12,219.45 185.37,221.64 181.02,226.92 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			154.23,192.21 147.68,203.72 161.7,209.34 161.87,219.6 166.06,219.14 168.72,223.96 174.63,221.01 185.37,221.64 191.12,219.45 
			189.42,215.69 193.92,214.92 191.91,209.62 191.12,203.55 180.56,203.72 174.8,197.65 173.85,189.86 167.18,192.21 164.83,189.4 
			156.74,185.36 153.31,187.54 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			180.56,203.72 191.12,203.55 201.09,209.95 209.67,210.57 211.84,207.75 209.98,200.92 214.02,199.35 214.02,193.14 216.5,191.28 
			213.08,182.87 208.11,182.87 202.81,185.36 199.69,183.16 200.94,179.76 195.99,177.64 190.67,175.4 186.64,177.58 183.66,184.25 
			181.31,187.99 173.85,189.86 174.8,197.65 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			158.28,171.66 152.36,174.76 153.6,180.98 156.74,185.36 164.83,189.4 167.18,192.21 173.85,189.86 181.31,187.99 183.66,184.25 
			186.64,177.58 183.5,170.07 180.39,170.07 178.2,163.25 175.72,163.25 175.72,155.45 172.91,150.78 167.6,156.38 156.09,157.94 
			153.31,162.63 158.28,167.28 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			282.55,206.22 280.36,204.35 274.75,204.04 273.2,201.53 266.34,201.53 265.1,198.43 266.95,196.24 262.91,192.82 262.28,189.1 
			259.51,189.1 254.82,189.1 254.82,192.52 247.04,192.52 251.55,197.65 245.01,208.08 244.38,216.81 248.75,224.11 261.82,233.45 
			271.33,230.2 275.99,232.37 283.48,217.73 279.72,210.9 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			189.09,319.88 189.09,316.78 180.07,316.78 175.43,313.35 169.19,313.35 169.19,320.83 164.5,327.97 161.1,328.29 158.11,332.92 
			156.09,336.06 158.28,342.31 166.39,347.92 170.42,345.1 173.56,346.99 176.66,343.86 181.94,343.86 184.13,341.67 182.58,338.88 
			187.86,335.13 191.91,331.71 190.99,321.75 		"/>

							<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
			195.99,177.64 201.87,173.2 200.61,166.98 202.4,161.53 197.19,160.97 195.39,160.94 190.88,158.82 188.33,161.54 188.33,166.67 
			183.5,170.07 186.64,177.58 190.67,175.4 		"/>
						</g>
						
						
						<polygon data-color={data[20]} fill="currentColor" points="384.27,389.81 383.79,374.51 379.96,374.85 378.47,389.32 371.65,387.65 361.68,392.64 
		352.53,403.97 356.35,407.96 363.64,408.12 374.13,417.6 383.61,424.25 387.45,417.28 387.77,398.31 	"/>
						<polygon data-color={data[20]} fill="currentColor" points="363.64,408.12 356.35,407.96 353.51,410.27 354.19,414.6 358.99,417.77 355.18,424.92 
		360.51,426.26 357.67,432.38 364.16,435.88 360.84,439.22 372.14,448.71 376.96,449.71 382.28,439.88 383.61,424.25 374.13,417.6 	
		"/>
						<polygon data-color={data[75]}fill="currentColor" points="339.78,23 334.24,26.28 333.16,27.88 330.45,27.64 328.23,28.47 325.1,31.42 324.92,33.61 
		328.96,34.78 329.81,37.26 331.38,37.2 339.58,40.89 341.01,40.89 343.89,40.91 345.43,41.74 352.1,39.08 355.31,39.96 
		360.06,41.42 360.67,38.54 360.94,36.77 357.95,35.51 354.66,37.48 353.09,37.34 353.75,33.72 353.38,28.5 351.34,27.36 
		350.21,23.2 	"/>
						<polygon data-color={data[92]} fill="currentColor" points="339.78,23 334.24,26.28 333.16,27.88 330.45,27.64 328.23,28.47 325.1,31.42 324.92,33.61 
		328.96,34.78 329.81,37.26 331.38,37.2 339.58,40.89 341.01,40.89 338.51,46.41 340.1,48.56 336.91,53.68 336.6,56.45 
		333.36,56.58 333.16,52.58 327.77,51.14 326.22,49.32 326.04,46.42 323.72,46.06 323.34,43.7 320.45,43.33 318.33,39.53 
		316.78,40.43 316.02,37.17 316.59,27.55 319.3,23.02 324.49,20.48 330.46,15.59 336.25,13.23 336.73,13.04 339.31,13.29 
		342.34,14.62 342.17,16.78 339.05,20.07 	"/>
						<polygon  data-color={data[93]} fill="currentColor" points="339.78,23 339.05,20.07 342.17,16.78 342.34,14.62 339.31,13.29 336.73,13.04 336.25,13.23 
		336.63,10.87 338.17,10.32 342.43,11.97 348.77,9.05 352.43,12.14 360.33,11.96 363.02,10.14 364.57,10.87 368.62,6.33 
		369.69,5.19 370.74,2.89 372.86,3.25 374.47,0.41 376.92,2.35 374.61,6.96 377.62,12.28 373.94,25.64 377.62,28.86 374.86,31.17 
		375.98,41.23 373.11,40.22 372.75,38.14 365.57,32.49 361.4,32.17 353.75,33.72 353.75,33.72 353.38,28.5 351.34,27.36 
		350.21,23.2 	"/>
						<polygon data-color={data[94]} fill="currentColor" points="352.1,39.08 355.31,39.96 360.06,41.42 360.67,38.54 360.94,36.77 357.95,35.51 354.66,37.48 
		353.09,37.34 353.75,33.72 361.4,32.17 365.57,32.49 372.75,38.14 373.11,40.22 375.98,41.23 379,48.22 374.4,57.44 376.25,63.89 
		372.1,67.56 370.34,65.84 366.69,58.21 355.71,61.11 352.43,60.57 351.66,59.66 347.23,60.57 346.08,55.67 343.37,56.22 
		340.3,54.22 338.23,54.44 336.6,56.45 336.91,53.68 340.1,48.56 338.51,46.41 341.01,40.89 343.89,40.91 345.43,41.74 	"/>

						<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		384.27,389.81 383.79,374.51 379.96,374.85 378.47,389.32 371.65,387.65 361.68,392.64 352.53,403.97 356.35,407.96 363.64,408.12 
		374.13,417.6 383.61,424.25 387.45,417.28 387.77,398.31 	"/>

						<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		363.64,408.12 356.35,407.96 353.51,410.27 354.19,414.6 358.99,417.77 355.18,424.92 360.51,426.26 357.67,432.38 364.16,435.88 
		360.84,439.22 372.14,448.71 376.96,449.71 382.28,439.88 383.61,424.25 374.13,417.6 	"/>

						<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		339.78,23 334.24,26.28 333.16,27.88 330.45,27.64 328.23,28.47 325.1,31.42 324.92,33.61 328.96,34.78 329.81,37.26 331.38,37.2 
		339.58,40.89 341.01,40.89 343.89,40.91 345.43,41.74 352.1,39.08 355.31,39.96 360.06,41.42 360.67,38.54 360.94,36.77 
		357.95,35.51 354.66,37.48 353.09,37.34 353.75,33.72 353.38,28.5 351.34,27.36 350.21,23.2 	"/>

						<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		339.78,23 334.24,26.28 333.16,27.88 330.45,27.64 328.23,28.47 325.1,31.42 324.92,33.61 328.96,34.78 329.81,37.26 331.38,37.2 
		339.58,40.89 341.01,40.89 338.51,46.41 340.1,48.56 336.91,53.68 336.6,56.45 333.36,56.58 333.16,52.58 327.77,51.14 
		326.22,49.32 326.04,46.42 323.72,46.06 323.34,43.7 320.45,43.33 318.33,39.53 316.78,40.43 316.02,37.17 316.59,27.55 
		319.3,23.02 324.49,20.48 330.46,15.59 336.25,13.23 336.73,13.04 339.31,13.29 342.34,14.62 342.17,16.78 339.05,20.07 	"/>

						<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		339.78,23 339.05,20.07 342.17,16.78 342.34,14.62 339.31,13.29 336.73,13.04 336.25,13.23 336.63,10.87 338.17,10.32 
		342.43,11.97 348.77,9.05 352.43,12.14 360.33,11.96 363.02,10.14 364.57,10.87 368.62,6.33 369.69,5.19 370.74,2.89 372.86,3.25 
		374.47,0.41 376.92,2.35 374.61,6.96 377.62,12.28 373.94,25.64 377.62,28.86 374.86,31.17 375.98,41.23 373.11,40.22 
		372.75,38.14 365.57,32.49 361.4,32.17 353.75,33.72 353.75,33.72 353.38,28.5 351.34,27.36 350.21,23.2 	"/>

						<polygon fill="none" stroke="#FFFFFF" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="
		352.1,39.08 355.31,39.96 360.06,41.42 360.67,38.54 360.94,36.77 357.95,35.51 354.66,37.48 353.09,37.34 353.75,33.72 
		361.4,32.17 365.57,32.49 372.75,38.14 373.11,40.22 375.98,41.23 379,48.22 374.4,57.44 376.25,63.89 372.1,67.56 370.34,65.84 
		366.69,58.21 355.71,61.11 352.43,60.57 351.66,59.66 347.23,60.57 346.08,55.67 343.37,56.22 340.3,54.22 338.23,54.44 
		336.6,56.45 336.91,53.68 340.1,48.56 338.51,46.41 341.01,40.89 343.89,40.91 345.43,41.74 	"/>
					</g>

				</svg>
			</div>
		)
	}

export default FranceDept;