import React from "react";
//import { Link } from "@reach/router";
import Link from 'gatsby-plugin-transition-link';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import slugify from 'slugify'

import "./styles.scss";

const Card: React.FunctionComponent<{ data: any, type: string, className?: string, color?: number }> = ({ data, type, className, color }) => {

  return (
    <div className={`${className} ${type}-card`} data-name={data.lastname}>
      <div className="card_inner">
        {'full' === type &&
          <div className="card-profile_icon">
            <img src={`/assets/images/svg/profile${!!color ? color : ''}.svg`} alt={` ${data.firstname} ${data.lastname} `} />
          </div>
        }
        <div className="card-profile_name">
          <span>{data.firstname}</span>
          <span>{data.lastname}</span>
        </div>
        {'full' !== type &&
          <div className="card-profile_identity">{data.identity.join(', ')}</div>
        }
        {'full' === type &&
          <div className="card-profile_metas">
            <div className="card-label">Je suis...</div>
            <div className="card-profile_identity">{data.identity.join(', ')}</div>
          </div>
        }
        {'full' === type &&
          <div className="card-profile_metas">
            <div className="card-label">J'habite...</div>
            <div className="card-profile_address">
              <span>{data.address}</span>
              <span>{data.postalCode}</span>
            </div>
          </div>
        }
        {'full' !== type &&
          <div className="card-profile_icon">
            <img src={`/assets/images/svg/profile${!!color ? color : ''}.svg`} alt={` ${data.firstname} ${data.lastname} `} />
          </div>
        }

        <AniLink className="btn btn-see"
          fade
          entry={{ delay: 0.5 }}
          to={`/annuaire/membres/${data.slug}`}>
          <span className="btn_label">Voir</span>
          <span className="btn_icon">
            <i className="fas fa-arrow-right"></i>
          </span>
        </AniLink>
        <AniLink className="btn btn-full btn-no-text"
          fade
          entry={{ delay: 0.5 }}
          to={`/annuaire/membres/${data.slug}`}>
          <span className="btn_label">Voir</span>
        </AniLink>
      </div>
    </div>
  )
}

export default Card;