import React from "react"
import SearchForm from './SearchForm';

import "./styles.scss";

const Search: React.FC<{ members: any, mode?: string, placeholder? : string, setFilters?: any }> = ({ members, mode, placeholder, setFilters }) => {
    return (
        <>
            {("alone" !== mode) ? 
                <div className="section-annuaire section-annuaire-search">
                    <div className="section_inner">
                        <div className="section-title">
                            <h1>
                                <span>Bienvenue sur L'annuaire</span><br />
                                <b>«&nbsp;Vu des Quartiers&nbsp;»</b>
                            </h1>
                        </div>
                        <div className="section-content">
                            <p>
                                <strong>
                                    Trouvez les contacts qui s'investissent au quotidien pour dynamiser les quartiers prioritaires de la ville...
                            </strong>
                            </p>
                            <SearchForm 
                                placeholder={'Mots-clés, noms de personnes, zones géographiques...'}
                                members={members}
                            />
                        </div>
                    </div>
                </div>
            : 
                <SearchForm 
                    placeholder={placeholder}
                    members={members}
                    setFilters={setFilters}
                />
            }
        </>
    );
}

export default Search;