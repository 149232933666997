import React, { useEffect, useState } from "react"
import Fuse from "fuse.js";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import * as cryptoJS from 'crypto-js';
import { navigate } from 'gatsby';

import "./styles.scss";
import { defaultFilters, uniqBy } from "../../../../../../utils/Data";
import { clone } from "../../../../../../utils/File";

const SearchForm: React.FC<{ members: any, placeholder?: string, setFilters?: any }> = ({ members, placeholder, setFilters }) => {

    const [search, setSearch] = useState<string>('');

    const [searchEngine, setSearchEngine] = useState<any>(null);

    const [searchCount, setSearchCount] = useState<number>(0);

    const [searchResults, setSearchResults] = useState<any>({
        contact: [],
        city: [],
        qpv: [],
        thematic: []
    });

    const searchOptions = {
        includeScore: true,
        includeMatches: true,
        useExtendedSearch: true,
        isCaseSensitive: false,
        threshold: 0.1,
        keys: [
            {
                name: 'lastname',
                weight: 3
            },
            {
                name: 'firstname',
                weight: 3
            },
            {
                name: 'city',
                weight: 3
            },
            {
                name: 'qpv',
                weight: 3
            },
            {
                name: 'thematicsSearch.id',
                weight: 3
            }
        ]
    }
    
    const onChangeSearch = async (value: string) => {
        setSearch(value);
        
        const tmp = await searchEngine.search(value.split(/\s/).join(' | '));
        
        let results = {
            contact: [],
            city: [],
            qpv: [],
            thematic: []
        };
        
        tmp.forEach((r: any) => {
            r.matches.forEach((m: any) => {
                switch(m.key){
                    case 'lastname':
                    case 'firstname':
                        results.contact.push({
                            lastname: r.item.lastname,
                            firstname: r.item.firstname,
                            slug: r.item.slug
                        });
                    break;
                    case 'city':
                        results.city.push({
                            id: cryptoJS.MD5(r.item.city.toLowerCase()).toString(),
                            name: r.item.city
                        });
                    break;
                    case 'qpv':
                        results.qpv.push({
                            id: cryptoJS.MD5(r.item.qpv.toLowerCase()).toString(),
                            name: r.item.qpv
                        });
                    break;
                    case 'thematicsSearch.id':
                        results.thematic.push({
                            id: cryptoJS.MD5(m.value.toLowerCase()).toString(),
                            name: m.value
                        });
                        
                    break;
                }
            })
        });
        
        results = {
            contact: uniqBy(results.contact, (co: any) => co.slug),
            city: uniqBy(results.city, (c: any) => c.id),
            qpv: uniqBy(results.qpv, (q: any) => q.id),
            thematic: uniqBy(results.thematic, (t: any) => t.id)
        }

        setSearchCount(results.contact.length + results.city.length + results.qpv.length + results.thematic.length);

        setSearchResults(results);
    }

    const storeSearch = (type: string, data: any) => {
        if(!!window.localStorage){
            let f:any = window.localStorage.getItem('filters');
            f = (f) ? JSON.parse(f) : clone(defaultFilters);
            
            if('thematics' === type){
                if(-1 === f[type].indexOf(data.name)){
                    f[type].push(data.name);
                }
            }else{
                f.location[type].push(data);
                f.location[type] = uniqBy(f.location[type], (l: any) => l.id);
            }
            setSearch('');
            setSearchCount(0);
            setSearchResults({
                contact: [],
                city: [],
                qpv: [],
                thematic: []
            });
            if(!!setFilters){
                setFilters(f);
            }else{
                window.localStorage.setItem('filters', JSON.stringify(f));
                navigate('/annuaire/membres');
            }
        }
    }

    useEffect(() => {
        const data = members.map((m: any) => {
            m.thematicsSearch = m.thematics.map((t: any) => { return {id: t}});
            return m;
        });
        
        setSearchEngine(new Fuse(data, searchOptions));
    }, [members]);

    return (
        <form className="form form-search" noValidate onSubmit={(e) => e.preventDefault()}>
            <div className="form_inner">
                <div className="form-row row row-block row-autocomplete">
                    <div className="input-wrapper">
                    <input type="text" name="search" placeholder={placeholder} value={search} onChange={(e) => onChangeSearch(e.currentTarget.value)} autoComplete="off" />
                    </div>
                    <div className={`search-results ${0 < searchCount ? "search-open" : null}`}>
                        <div className="search-results_inner">
                            <div className="search-results-group">

                                {0 < searchResults.contact.length &&
                                    <div className="search-results-subgroup">
                                        <label>Contacts</label>
                                        <ul>
                                            {searchResults.contact.map((sr: any) => (
                                                <li key={sr.slug}>
                                                    <span>
                                                        <AniLink className={`sublink`}
                                                            fade
                                                            to={`/annuaire/membres/${sr.slug}`}
                                                            entry={{
                                                                delay: 0.1
                                                            }}
                                                        >
                                                            {sr.lastname} {sr.firstname}
                                                        </AniLink>
                                                    </span>
                                                </li>
                                            ))
                                            }
                                        </ul>
                                    </div>
                                }
                                {0 < searchResults.city.length &&
                                    <div className="search-results-subgroup">
                                        <label>Commune</label>
                                        <ul>
                                            {searchResults.city.map((c: any) => (
                                                <li key={c.id} onClick={() => storeSearch('city', c)}>
                                                    <span>
                                                        {c.name}
                                                    </span>
                                                </li>
                                            ))
                                            }
                                        </ul>
                                    </div>
                                }
                                {0 < searchResults.qpv.length &&
                                    <div className="search-results-subgroup">
                                        <label>QPV</label>
                                        <ul>
                                            {searchResults.qpv.map((q: any) => (
                                                <li key={q.id} onClick={() => storeSearch('qpv', q)}>
                                                    <span>
                                                        {q.name}
                                                    </span>
                                                </li>
                                            ))
                                            }
                                        </ul>
                                    </div>
                                }
                                {0 < searchResults.thematic.length &&
                                    <div className="search-results-subgroup">
                                        <label>Thèmes</label>
                                        <ul>
                                            {searchResults.thematic.map((t: any) => (
                                                <li key={t.id} onClick={() => storeSearch('thematics', t)}>
                                                    <span>
                                                        {t.name}
                                                    </span>
                                                </li>
                                            ))
                                            }
                                        </ul>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default SearchForm;