import React, { useContext, useEffect, useState } from "react"
import { MemberProfile, Member } from "../../../../../../services/MemberService";
import Seo from "../../../../../structure/Seo/Seo"
import AniLink from "gatsby-plugin-transition-link/AniLink";

import "./styles.scss";
import ModalReport from "../../../../../element/Modal/ModalReport";
import { cleanAuth } from "../../../../../../services/AuthService";

const MemberPage: React.FunctionComponent<{ path: any, slug: string }> = ({ path, slug }) => {
  const [member, setMember] = useState<any>({});

  const [displayModal, setDisplayModal] = useState<boolean>(false);


  const getData = async () => {
    try{
      const response: Member = await MemberProfile(slug);
      setMember(response);
    }catch(error){
      if(!!error && !!error.message && error.message.match(/401/gi)){
        cleanAuth();
        document.location.href = '/annuaire';
      }
    }
  }

  useEffect(() => {
    if ('undefined' === typeof member.slug) {
      getData();
    }
  }, []);

  const toggleModal = () => {
    setDisplayModal(!displayModal);
  }

  const ucFirst = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <>
      <Seo title="Fiche membre" />

      <div className="page page-grid to-bottom page-private page-profile" data-theme="quaternary-25">

        <div className="page-actions">
          <AniLink className="btn btn-back btn-secondary"
            fade
            to={'/annuaire/membres'}
            entry={{
              delay: 0.5
            }}
          >
            <span className="btn_icon">
              <i className="fas fa-arrow-left"></i>
            </span>
            <span className="btn_label">Retour</span>
          </AniLink>
          <div className="page-actions_inner">

            <button className="btn btn-cta btn-secondary rounded" onClick={toggleModal} data-uk-toggle="target: #modalAbuse">
              <span className="btn_label">Signaler une erreur/anomalie</span>
              <span className="btn_icon">
                <i className="fas fa-pen-alt"></i>
              </span>
            </button>
          </div>
        </div>


        <div className="page_inner">
          {!!member.id &&
            <>
              <div className="col col-left">
                <div className="col_inner">
                  <div className="box">
                    <div className="box_inner">
                      <div className="box__head">
                        <span>Identité</span>
                      </div>
                      <div className="box__content">
                        <div className="profile_icon">
                          <img src="/assets/images/svg/profile.svg" alt={` ${member.firstname} ${member.lastname} `} />
                        </div>
                        <div className="profile_name">
                          <strong>{member.lastname}</strong><br />
                          <span>{member.firstname}</span>
                        </div>
                        <div className="profile_metas">
                          <span className="meta"><i className={`fas fa-${!!member.gender && ( member.gender === "homme" || member.gender === "Homme" ) ? "mars" : ( member.gender === "femme" || member.gender === "Femme") ? "venus" : "neuter"}`}></i> {member.gender}</span> <span className="meta"><i className="fas fa-birthday-cake"></i> {member.birthYear}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="box">
                    <div className="box_inner">
                      <div className="box__head">
                        <span>Contact</span>
                      </div>
                      <div className="box__content">
                        <div className="profile_metas">
                          <div className="meta-label">
                            <i className="fas fa-paper-plane"></i><span>Email</span>
                          </div>
                          <div className="meta-value cut">
                            {"" != member.email ? (
                              <a href={`mailto:${member.email}`} target="_blank">{member.email}</a>
                            ) : (
                                "--"
                              )
                            }
                          </div>
                        </div>
                        <div className="profile_metas">
                          <div className="meta-label">
                            <i className="fas fa-phone"></i><span>Téléphone</span>
                          </div>
                          <div className="meta-value">
                            {"" != member.phoneNumber ? (
                              <>
                                {member.phoneNumber.toString().length < 10 ? (
                                  <a href={`tel:+33(0)${member.phoneNumber}`} target="_blank">{0 +  member.phoneNumber}</a>
                                ) : (
                                    <a href={`tel:+33${member.phoneNumber}`} target="_blank">{member.phoneNumber}</a>
                                  )
                                }
                              </>
                            ) : (
                                <>"--"</>
                              )}
                          </div>
                        </div>
                        <div className="profile_metas">
                          <div className="meta-label availability">
                            <i className="fas fa-exclamation-circle"></i><span>Je ne suis pas disponible</span>
                          </div>
                          <div className="meta-value primary" dangerouslySetInnerHTML={{__html: "" != member.noContact ? member.noContact.replace(/,/, '<br>') : "--"}}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="box">
                    <div className="box_inner">
                      <div className="box__head">
                        <span>Localisation</span>
                      </div>
                      <div className="box__content">
                        <div className="profile_metas">
                          <div className="meta-label">
                            <i className="fas fa-map-marker-alt"></i><span>Adresse</span>
                          </div>
                          <div className="meta-value primary">
                            <span>{member.address}</span><br />
                            <span>{member.postalCode} {member.city}</span>
                          </div>
                        </div>
                        <div className="profile_metas">
                          <div className="meta-label">
                            <i className="fas fa-map-marked"></i><span>QPV</span>
                          </div>
                          <div className="meta-value primary">
                            {member.qpv}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-right">
                <div className="col_inner">
                  <div className="box">
                    <div className="box_inner">
                      <div className="box__head">
                        <span>Activité</span>
                      </div>
                      <div className="box__content">

                        <div className="profile_metas">
                          <div className="meta-label">
                            <i className="fas fa-id-card"></i><span>Je suis</span>
                          </div>
                          <div className="meta-value primary" dangerouslySetInnerHTML={{ __html: "" != member.jobPosition ? member.jobPosition.map((j: string) => ucFirst(j)).join(',<br />') + (!!member.volunteer ? ', <br>Bénévole' : '') : '--' }}></div>
                        </div>

                        <div className="profile_metas">
                          <div className="meta-label">
                            <i className="fas fa-user"></i><span>Je me définis comme</span>
                          </div>
                          <div className="meta-value primary" dangerouslySetInnerHTML={{ __html: "" != member.identity ? member.identity.map((j: string) => ucFirst(j)).join(',<br />') : '--' }}></div>
                        </div>

                        <div className="profile_metas">
                          <div className="meta-label">
                            <i className="fas fa-briefcase"></i><span>Mon domaine d'activité</span>
                          </div>
                          {"" != member.domain ? (
                            <div className="meta-value primary" dangerouslySetInnerHTML={{ __html: member.domain }}></div>
                          ) : (
                              <div className="meta-value primary">--</div>
                            )}
                        </div>

                        <div className="profile_metas">
                          <div className="meta-label">
                            <i className="fas fa-tags"></i><span>Les thématiques sur lesquelles je peux intervernir</span>
                          </div>
                          <div className="meta-value primary">
                            <ul className="tags-wrapper">
                              {0 < member.thematics.length &&
                                member.thematics.map((t: string, ti: number) => (
                                  <li className="tags-tag" key={member.id + '-' + ti}>
                                    <div className="tag_inner"><span>{t}</span></div>
                                  </li>
                                ))
                              }
                            </ul>
                          </div>
                        </div>


                        <div className="profile_metas">
                          <div className="meta-label">
                            <i className="fas fa-comment-alt"></i><span>En quelques mots</span>
                          </div>
                          <div className="meta-value primary">
                            <div dangerouslySetInnerHTML={{ __html: member.introduction }}></div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }

        </div>
      </div>


        {!!displayModal &&
          <ModalReport
            slug={slug}
            toggleModal={toggleModal}
          />
        }

    </>
  );
}

export default MemberPage