import React, { useState } from "react";
import { Report } from "../../../services/MemberService";

import "./styles.scss";

type ModalReport = {
  slug: string,
  toggleModal: any
}

const ModalReport = ({ slug, toggleModal }: ModalReport) => {
  const [message, setMessage] = useState<string>('');

  const [responseMessage, setResponseMessage] = useState<string>('');

  const sendReport = () => {
    Report(slug, message)
    .then(response => {
      if('ok' === response.status){
        setMessage('');
        setResponseMessage('Votre message a bien été envoyé à l\'administrateur du site.');
      }else{
        setResponseMessage('Un problème est survenu. Veuillez réessayer ultérieurement.');
      }
      setTimeout(() => {
        setResponseMessage('');
      }, 2500);
    })
  }

  return (
    <div id='modalAbuse' data-uk-modal className="modal modal-confirm mini uk-modal uk-open" data-theme="secondary-50">
      <div className="modal_body uk-modal-dialog uk-modal-body radius radius-small radius-bottom" data-theme="primary">
        <div className="modal__inner">
        <button className="btn btn-close btn-no-text" type="button" onClick={toggleModal} 
        // data-uk-toggle={`target: #modalAbuse`}
        >
            <span className="btn_icon"><i className="fas fa-times"></i></span>
            <span className="btn_label">Fermer</span>
          </button>
          <div className="modal-title">
            <h2>Signaler une erreur/anomalie</h2>
          </div>

          <div className="modal-content">
          <div className="modal-content_inner">
            <form className="form form-abuse" onSubmit={(e) => e.preventDefault()}>
              <div className="form_inner">
                <div className="form-row row row-block row-textarea">
                  <label><span>Message</span></label>
                  <textarea value={message} onChange={(e) => setMessage(e.currentTarget.value)}></textarea>
                </div>
              </div>
            </form>
          </div>
          </div>
          <div className="modal-actions">
            <button type="button" onClick={sendReport} className={`${status} btn btn-secondary btn-cta rounded`} disabled={'' === message}>
              <span className="btn_label">Envoyer</span>
              <span className="btn_icon"><i className='fas fa-paper-plane'></i></span>
            </button>
            {!!responseMessage &&
              <div className="modal-response-message">{responseMessage}</div>
            }
          </div>
        </div>
      </div>
    </div>

  )
};


export default ModalReport;