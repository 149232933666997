import React, { useEffect } from "react";
import Link from 'gatsby-plugin-transition-link';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Split from "../../../../../structure/Split/Split";
import Card from "../../../User/Modules/Card/Card";
import { Member } from "../../../../../../services/MemberService";
import CountUp from 'react-countup';

import "./styles.scss";
import { clearLSFilters } from "../../../../../../utils/Data";


const Contacts: React.FunctionComponent<{ count: number, last: Member[] }> = ({ count, last }) => {

    const lastProfiles = () => {
        const cards = [<div key="emptyCard" className="card card-profile card-profile-empty"><div className="card_inner"><span>Derniers<br />profils<br /> enregistrés<br /> sur l'annuaire des quartiers</span></div></div>];
        if (last.length) {
            last.slice(0, 7).forEach((m: any) =>
                cards.push(<Card className="card card-profile mini" key={m.id} type="short" data={m} color={Math.floor(Math.random() * 4)} />)
            )
        }
        return cards;
    }

    return (
        <>
            <div className="section-annuaire section-annuaire-contacts">
                <Split
                    mode={`fluid`}
                    left={
                        <section className="section-main section-card section-contact section-contact-description">
                            <div className="section_inner section_inner radius radius-small radius-left" data-theme="white">
                                <div className="section-title">
                                    <div className="contact-counter">
                                        <CountUp
                                            start={0}
                                            end={count}
                                            duration={0.75}
                                            separator=" "
                                            decimals={0}
                                            decimal=","
                                            prefix=""
                                            suffix=""
                                        // onEnd={() => console.log('Ended! 👏')}
                                        // onStart={() => console.log('Started! 💨')}
                                        />
                                    </div>
                                    <div className="">contact{1 < count ? 's' : ''}</div>
                                </div>
                                <div className="section-content">
                                    <p>
                                        Retrouvez ici les profils de tous les contacts qui participent et interviennent au quotidien dans les quartiers prioritaires de la ville.
                                    </p>
                                    <div className="cta-wrapper">
                                        <AniLink
                                            fade
                                            entry={{ delay: 0.5 }}
                                            to="/annuaire/membres"
                                        >
                                            <button className="btn btn-cta btn-secondary rounded">
                                                <span className="btn_label">Tous les contacts</span>
                                                <span className="btn_icon"><i className="fas fa-arrow-right"></i></span>
                                            </button>
                                        </AniLink>
                                    </div>

                                </div>
                            </div>
                        </section>
                    }
                    right={
                        <section className="section-main section-card expand section-contact section-contact-grid">
                            <div className="section_inner section_inner">
                                <div className="grid grid-profile">
                                    <div className="grid_inner">
                                        {lastProfiles()}
                                    </div>
                                </div>
                            </div>
                        </section>
                    }
                />

            </div>
        </>
    );
}

export default Contacts;