import React, { FunctionComponent, useEffect, useState } from 'react';

import "./styles.scss";


const Guyane: FunctionComponent<{
    data: number,
}> = ({
    data
}) => {

    // console.log('--guyane', data);


        return (
            <div className="map-wrapper-mini">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
                    <g className={`map-svg map-recap map-guyane map-fr-outre-mer`} strokeWidth="2">
                        <path data-color={data} fill="currentColor" d="M67.41 33.5L44.08 10.17H25.09V16l-8.84 8.84v16.99L25.42 51v6.5l-4.33 4.33v6.67l-7 7 4.33 4.33h8.16L31.42 75l3.16 3.33h17.34l9.83-10v-10l14.16-14.16V33.5z" />
                    </g>
                </svg>
            </div>
        )
    }

export default Guyane;