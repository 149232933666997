import React, { useEffect, useState } from "react"
import CountUp from 'react-countup';
import MapFranceDept from '../../../../element/Maps/FranceDept/FranceDept';
import MapMartinique from '../../../../element/Maps//OutreMer/Martinique/MartiniqueAlone';
import MapGuadeloupe from '../../../../element/Maps/OutreMer/Guadeloupe/GuadeloupeAlone';
import MapReunion from '../../../../element/Maps/OutreMer/Reunion/ReunionAlone';
import MapGuyane from '../../../../element/Maps/OutreMer/Guyane/GuyaneAlone';
import MapMayotte from '../../../../element/Maps/OutreMer/Mayotte/MayotteAlone';
import MapSaintMartin from '../../../../element/Maps/OutreMer/SaintMartin/SaintMartinAlone';
import MapPolynesie from '../../../../element/Maps/OutreMer/Polynesie/PolynesieAlone';

import "./styles.scss";
import { getDepartment } from "../../../../../utils/Data";

const RecapitulationMap: React.FC<{ members: any }> = ({ members }) => {

  const [stats, setStats] = useState({
    thematics: [],
    qpv: [],
    region: {},
    thematics_population: {}
  })

  /**
   * Get statistics from members list
   */
  const getStatistics = () => {
    //Get thematics
    const thematics: any = [];
    members
      .map((m: any) => m.thematics)
      .forEach((tbm: any) => {
        tbm.forEach((t: any) => {
          if (-1 === thematics.indexOf(t)) {
            thematics.push(t);
          }
        });
      });

    //Get qpv
    const qpv: any = [];
    members
      .map((m: any) => m.qpv)
      .forEach((qp: any) => {
        if (-1 === qpv.indexOf(qp)) {
          qpv.push(qp);
        }
      });

    //Get count member by region
    const region: any = {};
    members
      .map((m: any) => getDepartment(m.postalCode))
      .forEach((pc: any) => {
        if ('undefined' === typeof region[pc]) {
          region[pc] = 0;
        }
        region[pc]++;
      });

    for (let r in region) {
      region[r] = Math.round((region[r] / members.length) * 100)
    }

    //Get count member by thematics
    const thematics_population: any = {};
    members
      .map((m: any) => m.thematics)
      .forEach((tbm: any) => {
        tbm.forEach((t: any) => {
          if ("undefined" === typeof thematics_population[t]) {
            thematics_population[t] = 0;
          }
          thematics_population[t]++;
        }
        );
      });

    const sortableThematics = Object.fromEntries(
      Object.entries(thematics_population).sort(([, a], [, b]) => b - a)
    );

    // for (
    //   let i in sortableThematics
    // ) {
    //   console.log(i, sortableThematics[i])
    // };

    setStats({ thematics: thematics, qpv: qpv, region: region, thematics_population: sortableThematics });




  }

  useEffect(() => {
    if (members.length) {
      getStatistics();
    }
  }, [members]);


  return (
    <>
      <div className="dataviz dataviz-map-recap">
        <div className="dataviz_inner">
          <div className="dataviz_split-left">
            <div className="dataviz-edito">
              Actuellement l'annuaire des quartiers c'est
              <div className="contact-counter">
                <CountUp
                  className="counter-value"
                  start={0}
                  end={members.length}
                  duration={0.75}
                  separator=" "
                  decimals={0}
                  decimal=","
                  prefix=""
                  suffix=""
                // onEnd={() => console.log('Ended! 👏')}
                // onStart={() => console.log('Started! 💨')}
                />
                <span className="counter-unity"> contact{1 < members.length ? 's' : ''}</span>
              </div>
            qui agissent majoritairement dans

             {0 < Object.entries(stats.thematics_population).length &&
                <ul className="tags-wrapper">
                  {Object.entries(stats.thematics_population).filter((tp, tpi) => 5 > tpi).map((t: any, ti: number) => {
                    return (
                      <li key={ti + new Date().getTime()} className="tags-tag tag-theme">
                        <div className="tag_inner"><span>{t[0]}</span></div>
                      </li>
                    )
                  }
                  )}
                </ul>
              } et qui vivent dans

              <div className="contact-counter">
                <CountUp
                  className="counter-value"
                  start={0}
                  end={stats.qpv.length}
                  duration={0.75}
                  separator=" "
                  decimals={0}
                  decimal=","
                  prefix=""
                  suffix=""
                // onEnd={() => console.log('Ended! 👏')}
                // onStart={() => console.log('Started! 💨')}
                />
                <span className="counter-unity"> QPV</span>
              </div>

            </div>
            <div className="dataviz-legend" data-uk-sticky="media: (max-width: 767px);">
              <p>Répartition des contacts par départements</p>
              <ul className="legends">
                <li>
                  <div>
                    <span></span>
                    <b>0</b>
                  </div>
                </li>
                <li>
                  <div>
                    <span></span>
                    <b>Moins de<br /> 10%</b>
                  </div>
                </li>
                <li>
                  <div>
                    <span></span>
                    <b>De 10%<br /> à 20%</b>
                  </div>
                </li>
                <li>
                  <div>
                    <span></span>
                    <b>De 20%<br /> à 40%</b>
                  </div>
                </li>
                <li>
                  <div>
                    <span></span>
                    <b>De 40%<br /> à 60%</b>
                  </div>
                </li>
                <li>
                  <div>
                    <span></span>
                    <b>Plus de<br /> 60%</b>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="dataviz_split-right">
            <div className="maps">
              <div className="maps_inner">
                <div className="map-france">
                  <MapFranceDept
                    data={stats.region}
                  />
                </div>
                <div className="map-outre-mer">
                  <ul>
                    <li>
                      <MapMartinique
                        data={stats.region[972]}
                      />
                      <span>Martinique</span>
                    </li>
                    <li>
                      <MapGuyane
                        data={stats.region[973]}
                      />
                      <span>Guyane</span>
                    </li>
                    <li>
                      <MapGuadeloupe
                        data={stats.region[971]}
                      />
                      <span>Guadeloupe</span>
                    </li>
                    <li>
                      <MapMayotte
                        data={stats.region[976]}
                      />
                      <span>Mayotte</span>
                    </li>
                    <li>
                      <MapReunion
                        data={stats.region[974]}
                      />
                      <span>Réunion</span>
                    </li>
                    <li>
                      <MapSaintMartin
                        data={stats.region[978]}
                      />
                      <span>Saint-Martin</span>
                    </li>
                    <li>
                      <MapPolynesie
                        data={stats.region[987]}
                      />
                      <span>Polynésie<br />française</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </>
  );
}

export default RecapitulationMap;