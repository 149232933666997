import React, { FunctionComponent, useEffect, useState } from 'react';

import "./styles.scss";


const SaintMartin: FunctionComponent<{
    data: number
}> = ({
    data
}) => {


        return (
            <div className="map-wrapper-mini">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
                    <g className={`map-svg map-recap map-saint-matin map-fr-outre-mer`} strokeWidth="2">
                        <path data-color={data} fill="currentColor" d="M64.77 55.41l-2.49-2.56 6.41-6.57-6.77-6.94 7.48-7.67L61.92 24l-6.06 6.21-2.49-2.56-4.63 4.75 2.49 2.56-5.34 5.48-3.56-3.65-12.47 12.78V66h3.92l9.98-10.23h6.06l5.7 5.84h14.25V55.4h-5v.01zm-45.24-3.29v4.38h-6.77l-7.13 7.3L1 59.06v-6.94h18.53zM89 28.75h-8.55V24H89v4.75z" />
                    </g>
                </svg>
            </div>
        )
    }

export default SaintMartin;