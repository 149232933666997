import React from "react"
import SearchForm from './SearchForm';

import "./styles.scss";

const SearchQpv: React.FC<{

 }> = ({ 

 }) => {
    return (
        <section className="section section-main section-page section-main-over section-annuaire-search-qpv-wrapper">
        <div className="section_inner radius radius-small radius-bottom" data-theme="white">
          <div className="section-title">
            <div className="section-annuaire section-annuaire-search-qpv">
              <div className="section_inner">
                <div className="section-content">
                  <p>
                    Vous voulez vérifier si votre adresse est bien dans un Quartier Prioritaire&nbsp;de la Ville&nbsp;? faites votre recherche sur le site
                    </p>
                  <div className="cta-wrapper">
                    <a className="btn btn-cta btn-external rounded" href="https://sig.ville.gouv.fr" target="_blank" rel="noopener">
                        <span className="btn_label upp">sig.ville.gouv.fr</span>
                        <span className="btn_icon"><i className="fas fa-external-link-alt"></i></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default SearchQpv;