import React, { useContext, useEffect, useState } from "react";
import { cleanAuth, isLoggedIn } from '../../../../services/AuthService';
import { MemberLast, Member, MemberAll } from "../../../../services/MemberService";
import Contacts from './Index/Contacts/Contacts';
import Search from "./Index/Search/Search";
import SearchQpv from "./Index/Search-qpv/Search-Qpv";

import Seo from "../../../structure/Seo/Seo"
import Connection from "../User/Modules/Connection/Connection";
import CitySkyline from "../../../element/Svg/CitySkyline";

import "./styles.scss";
import Description from "./Index/Description";
import Split from "../../../structure/Split/Split";
import LostPassword from "../User/Modules/LostPassword/LostPassword";
import RecapitulationMap from "./RecapitulationMap/RecapitulationMap";
import { clearLSFilters } from "../../../../utils/Data";

const Index: React.FunctionComponent<{ path: any }> = ({ path }) => {

  const [lpStep, setLpStep] = useState<string>('register');

  const [members, setMembers] = useState<Member[]>([]);

  const [membersLast, setMembersLast] = useState<Member[]>([]);

  const [scopeLogged, setScopeLogged] = useState<boolean>(false);

  const getData = async () => {
    try{
      const tmpAll: Member[] = await MemberAll();
      setMembers(tmpAll);
      const tmpLast: Member[] = await MemberLast();
      setMembersLast(tmpAll);
    }catch(error:any){
      if(!!error && !!error.message && error.message.match(/401/gi)){
        cleanAuth();
        document.location.reload();
      }
    }
  }

  useEffect(() => {
    if (isLoggedIn()) {
      clearLSFilters();
      setScopeLogged(true);
      getData();
    }
  }, []);

  return (
    <>
      {!scopeLogged &&
        <Seo title="Annuaire" />
      }
      <div key={scopeLogged ? 'logged' : 'notLogged'} className={`page page-grid ${scopeLogged ? 'page-annuaire page-annuaire-index' : 'page-connexion to-bottom'}`} data-theme={scopeLogged ? 'secondary-50' : 'quaternary-25'}>
        <div className="page_inner">
          {scopeLogged ?
            <>
              <section className="section section-main  section-main-over section-page section-header section-description">
                <div className="section_inner radius radius-xl radius-right" data-theme="primary">
                  <div className="section-title">
                    <Search members={members} />
                  </div>
                  <CitySkyline />
                </div>
              </section>
              <SearchQpv />
              <Contacts count={members.length} last={membersLast} />
            </>
            :
            <>
              <Description />
              <Split
                mode={`fluid`}
                left={<Connection setLpStep={setLpStep} onRegisterPage={false} classElt={`radius radius-small radius-left`} dataTheme={`white`} />}
                right={<LostPassword step={lpStep} setLpStep={setLpStep} mode="signin" />}
              />
            </>
          }
        </div>
      </div>
      {scopeLogged &&
        <div className="page page-grid page-annuaire page-annuaire-index-under" data-theme="primary">
          <div className="page_inner">
            <section className="section section-main section-main-under section-page">
              <div className="section_inner radius radius-small radius-bottom radius-left" data-theme="white">
                <RecapitulationMap members={members} />
              </div>
            </section>
          </div>
        </div>
      }
    </>
  );
}

export default Index;