import React, { FunctionComponent, useEffect, useState } from 'react';

import "./styles.scss";


const Polynesie: FunctionComponent<{
    data: number
}> = ({
    data
}) => {



        return (
            <div className="map-wrapper-mini">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224 125">
                    <g className={`map-svg map-recap map-polynesie map-fr-outre-mer`} strokeWidth="2">
                        <path data-color={data} fill="currentColor" d="M112.64 53.38h13.71l4.4 4.39h7.29v5.5l2.8 2.8v18.68l3.3 3.3h12.79v-3l7.94 7.94v9.34l-6.47 6.47h-7.17l-9.18-9.18v-4.48l-5.85-5.85h-5.85l-5.37 5.37h-10.32l-6.15-6.15h-8.53V75.16l-3.06-3.06V60.97l5.72-5.72h8.13l1.87-1.88z" />
                        <path data-color={data} fill="currentColor" d="M55.41 49.65h19.15l3.05 3.05-4.07 4.07v9.14H65.6c-.11 0-10.13-10.13-10.13-10.13l-.06-6.13z" />
                        <path data-color={data} fill="currentColor" d="M100.18 2.12h6.19l3.5 3.5v3.74l-3.55 3.55-3.5-3.5-2.67 2.68-2.72-2.73 2.75-2.74z" />
                        <path data-color={data} fill="currentColor" d="M1.24 76.56L3.6 74.2v-2.36h1.98l3.75 3.74v2.32l-2.25 2.25-2.06-2.06H1.24z" />
                        <path data-color={data} fill="currentColor" d="M218.64 105.52h3.13v3.12h-1.62l-1.51-1.51z" />
                    </g>
                </svg>
            </div>
        )
    }

export default Polynesie;