import React, { FunctionComponent, useEffect, useState } from 'react';

import "./styles.scss";


const Mayotte: FunctionComponent<{
    data: number,
}> = ({
    data
}) => {



        return (
            <div className="map-wrapper-mini">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
                    <g className={`map-svg map-recap map-mayotte map-fr-outre-mer`} strokeWidth="2">
                        <path data-color={data} fill="currentColor" d="M32.92 59.17v4.33h-5.33v-4.33h5.33zm24.66-25v-6.5l-4.5-4.5h-14l-9.83-9.83L19.59 23l6.33 6.33h6.67v18.5l10.5 10.5v5.5H32.92v6.5l6.33 6.33 4.5-4.5 4.17 4.17 4.67-4.67L45.92 65l10.67-10.67-6.5-6.5v-5.67l7.49-7.99zm5.84.33l4.67 4.67 2.33-2.33V27.5l-7 7z" />
                    </g>
                </svg>
            </div>
        )
    }

export default Mayotte;

