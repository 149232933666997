import React, { useEffect, useState } from 'react';
import CsvDownloader from 'react-csv-downloader';
import { Member } from '../../../services/MemberService';

const ButtonDownload: React.FC<{ filename: string, buttonTitle: string, data: Member[] }> = ({ filename, buttonTitle, data }) => {

  const columns = [
    {
      id: 'firstname',
      displayName: 'Prénom'
    },
    {
      id: 'lastname',
      displayName: 'Nom'
    },
    {
      id: 'gender',
      displayName: 'Sexe'
    },
    {
      id: 'email',
      displayName: 'Email'
    },
    {
      id: 'qpv',
      displayName: 'QPV'
    },
    {
      id: 'address',
      displayName: 'Adresse'
    },
    {
      id: 'postalCode',
      displayName: 'Code postal'
    },
    {
      id: 'city',
      displayName: 'Ville'
    },
    {
      id: 'birthYear',
      displayName: 'Année de naissance'
    },
    {
      id: 'domain',
      displayName: 'Domaine'
    },
    {
      id: 'identity',
      displayName: 'Identité'
    }, ,
    {
      id: 'thematics',
      displayName: 'Thèmes'
    },
    {
      id: 'introduction',
      displayName: 'Présentation'
    },
    {
      id: 'jobPosition',
      displayName: 'Poste occupé'
    },
    {
      id: 'noContact',
      displayName: 'Horaire de contact'
    },
    {
      id: 'phoneNumber',
      displayName: 'Numéro de téléphone'
    }
  ]

  const [csvData, setCsvData] = useState<any>([]);

  const prepareCSV = () => {
    const d = data.map((da: any) => {
      return {
        firstname: da.firstname,
        lastname: da.lastname,
        gender: da.gender,
        email: da.email,
        qpv: da.qpv,
        address: da.address,
        postalCode: da.postalCode,
        city: da.city,
        birthYear: da.birthYear,
        domain: da.domain,
        identity: da.identity.join(', '),
        thematics: da.thematics.join(', '),
        introduction: da.introduction,
        jobPosition: da.jobPosition.join(', '),
        noContact: da.noContact,
        phoneNumber: da.phoneNumber
      }
    });
    setCsvData(d);
  }

  useEffect(() => {
    prepareCSV()

    return () => {
      setCsvData([]);
    }
  }, [data]);

  return (
    <CsvDownloader
      filename={filename}
      separator=","
      wrapColumnChar='"'
      columns={columns}
      datas={csvData}
      text={buttonTitle}
    />
  )
}

export default ButtonDownload;