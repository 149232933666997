import React, { useEffect, useState } from "react"
import { Member, MemberAll } from "../../../../../services/MemberService";
import Card from "../../User/Modules/Card/Card";
import Filters from "./Filters";
import handleViewport from 'react-in-viewport';
import GeoPoint from 'geo-point';
import CountUp from 'react-countup';
import { defaultFilters, getDepartment } from "../../../../../utils/Data";
import Search from "./../Index/Search/Search";
import { clone } from "../../../../../utils/File";
import CitySkyline from "../../../../element/Svg/CitySkyline";
import { cleanAuth } from "../../../../../services/AuthService";

const MembersContentData: React.FunctionComponent<{ forwardedRef: any }> = ({ forwardedRef }) => {
  return <div id="viewportBlock" className="viewport-block" ref={forwardedRef}>&nbsp;</div>
}

const ViewportBlock = handleViewport(MembersContentData, {})

const MembersPage: React.FunctionComponent<{ path: any }> = ({ path }) => {

  const maxPerPage = 24;

  const [maxPage, setMaxPage] = useState(1);

  const [page, setPage] = useState<number>(1);

  const [members, setMembers] = useState<Member[]>([]);

  const [filteredMembers, setFilteredMembers] = useState([]);

  const [filters, setFilters] = useState(clone(defaultFilters));
  const [boxfilter, setFiltersBox] = useState(false);

  /**
   * 
   * @param member 
   * @deprecated
   */
  const filterMember = (member: Member) => {
    // If not filter then display member without checking
    if (0 === getFiltersCount()) {
      return true;
    }

    let filtered = true;

    if (!!filters.geolocation.latitude && !!filters.geolocation.longitude) {
      if (!!member.location && 2 === member.location.length) {
        const startPoint = new GeoPoint(filters.geolocation.latitude, filters.geolocation.longitude);
        // const endPoint = new GeoPoint(member.location.coordinates[0], member.location.coordinates[1]);
        const endPoint = new GeoPoint(parseFloat(member.location[0]), parseFloat(member.location[1]));
        const distance = startPoint.calculateDistance(endPoint);
        filtered = (distance <= (filters.radius * 1000));
      } else {
        filtered = false;
      }
    }

    // if (!!filters.location) {
    //   const rExp = new RegExp(filters.location, 'gi');
    //   if (!member.address.match(rExp) && !member.city.match(rExp) && !member.postalCode.match(rExp)) {
    //     filtered = false;
    //   }
    // }

    let foundLocation = 0;
    if (filtered && (!!filters.location.region && 0 < filters.location.region.length) ||
      (!!filters.location.department && 0 < filters.location.department.length) ||
      (!!filters.location.city && 0 < filters.location.city.length) ||
      (!!filters.location.qpv && 0 < filters.location.qpv.length)
    ) {
      if (0 < filters.location.department.length) {
        filters.location.department.forEach((l: any) => {
          if (l.name === member.department) {
            foundLocation++;
          }
        });

      }
      if (0 < filters.location.region.length) {
        filters.location.region.forEach((r: any) => {
          if (r.name === member.region) {
            foundLocation++;
          }
        });
      }
      if (0 < filters.location.city.length) {
        filters.location.city.forEach((c: any) => {
          if (c.name === member.city) {
            foundLocation++;
          }
        });
      }
      if (0 < filters.location.qpv.length) {
        filters.location.qpv.forEach((q: any) => {
          if (q.name === member.qpv) {
            foundLocation++;
          }
        });
      }

      const totalFiltersLocation = filters.location.department.length + filters.location.region.length + filters.location.city.length + filters.location.qpv.length;
      filtered = (totalFiltersLocation === foundLocation);
    }

    if (filtered && 0 < filters.types.length) {
      let foundIdentity = 0;
      member.identity.forEach((i: string) => {
        if (-1 !== filters.types.indexOf(i)) {
          foundIdentity++;
        }
      });

      filtered = (filters.types.length === foundIdentity);
    }

    if (filtered && 0 < filters.thematics.length) {
      let foundThematics = 0;
      member.thematics.forEach((t: string) => {
        if (-1 !== filters.thematics.indexOf(t)) {
          foundThematics++;
        }
      });
      filtered = (foundThematics === filters.thematics.length);
    }

    return filtered;
  }

  /**
   * @deprecated
   * @param member 
   */
  const filterMemberDeprecated = (member: Member) => {

    // If not filter then display member without checking
    if (0 === getFiltersCount()) {
      return true;
    }

    let filtered = false;

    if (!!filters.geolocation.latitude && !!filters.geolocation.longitude) {
      if (!!member.location && 2 === member.location.length) {
        const startPoint = new GeoPoint(filters.geolocation.latitude, filters.geolocation.longitude);
        // const endPoint = new GeoPoint(member.location.coordinates[0], member.location.coordinates[1]);
        const endPoint = new GeoPoint(parseFloat(member.location[0]), parseFloat(member.location[1]));
        const distance = startPoint.calculateDistance(endPoint);
        const inArea = (distance <= (filters.radius * 1000));
        if (!!inArea) {
          filtered = true;
        }
      }
    }

    // if (!!filters.location) {
    //   const rExp = new RegExp(filters.location, 'gi');
    //   if (!member.address.match(rExp) && !member.city.match(rExp) && !member.postalCode.match(rExp)) {
    //     filtered = false;
    //   }
    // }

    let foundLocation = false;
    if ((!!filters.location.region && 0 < filters.location.region.length) ||
      (!!filters.location.department && 0 < filters.location.department.length) ||
      (!!filters.location.city && 0 < filters.location.city.length) ||
      (!!filters.location.qpv && 0 < filters.location.qpv.length)
    ) {
      if (0 < filters.location.department.length) {
        filters.location.department.forEach((l: any) => {
          if (l.name === member.department) {
            foundLocation = true;
          }
        });
      }
      if (0 < filters.location.region.length) {
        filters.location.region.forEach((r: any) => {
          if (r.name === member.region) {
            foundLocation = true;
          }
        });
      }
      if (0 < filters.location.city.length) {
        filters.location.city.forEach((c: any) => {
          if (c.name === member.city) {
            foundLocation = true;
          }
        });
      }
      if (0 < filters.location.qpv.length) {
        filters.location.qpv.forEach((q: any) => {
          if (q.name === member.qpv) {
            foundLocation = true;
          }
        });
      }
      if (!!foundLocation) {
        filtered = true;
      }
    }

    if (0 < filters.types.length) {
      member.identity.forEach((i: string) => {
        if (-1 !== filters.types.indexOf(i)) {
          filtered = true;
        }
      });
    }

    if (0 < filters.thematics.length) {
      member.thematics.forEach((t: string) => {
        if (-1 !== filters.thematics.indexOf(t)) {
          filtered = true;
        }
      });
    }

    return filtered;
  }

  const saveFilters = (f: any) => {
    if (!!window.localStorage) {
      window.localStorage.setItem('filters', JSON.stringify(f));
    }
    setFilters(f);
  }

  const displayMembers = (list: any) => {
    if (0 < list.length) {
      const fMembers = list.filter((m: any) => filterMember(m));
      setPage(1);
      setFilteredMembers(fMembers);
      setMaxPage(Math.round(fMembers.length / maxPerPage));
    }
  }

  const getFiltersCount = () => {
    let count = 0;
    if (!!filters.location.region && 0 < filters.location.region.length) {
      count += filters.location.region.length;
    }
    if (!!filters.location.city && 0 < filters.location.city.length) {
      count += filters.location.city.length;
    }
    if (!!filters.location.department && 0 < filters.location.department.length) {
      count += filters.location.department.length;
    }
    if (!!filters.location.qpv && 0 < filters.location.qpv.length) {
      count += filters.location.qpv.length;
    }
    if (!!filters.geolocation.latitude) {
      count++;
    }
    if (0 < filters.types.length) {
      count += filters.types.length;
    }
    if (0 < filters.thematics.length) {
      count += filters.thematics.length;
    }
    return count;
  }

  const getData = async () => {
    try{
      const response: Member[] = await MemberAll();
      setMembers(response);
      if (!!window.localStorage) {
        const f = window.localStorage.getItem('filters');
       
        if (!!f) {
          setFilters(JSON.parse(f));
        } else {
          displayMembers(response);
        }
      } else {
        displayMembers(response);
      }
    }catch(error){
      if(!!error && !!error.message && error.message.match(/401/gi)){
        cleanAuth();
        document.location.href = '/annuaire';
      }
    }
  }

  useEffect(() => {
    if (!members.length) {
      getData();
      
      // MemberAll()
      //   .then((response: Member[]) => {
      //     setMembers(response);

      //     if (!!window.localStorage) {
      //       const f = window.localStorage.getItem('filters');
      //       console.log(f);
      //       console.log(f, !!f);
      //       if (!!f) {
      //         setFilters(JSON.parse(f));
      //       } else {
      //         displayMembers(response);
      //       }
      //     } else {
      //       displayMembers(response);
      //     }
      //   }, error => {
      //     console.log('---error');
      //     console.log(error);
      //   });
    }

    // return function cleanup() {
    //     console.log('leave page');
    // }

  }, []);

  useEffect(() => {
    displayMembers(members);
  }, [filters]);


  const onChangeFilterBox = () => {
    boxfilter ? setFiltersBox(false) : setFiltersBox(true);
  }


  return (
    <div className="page page-grid-full  to-bottom  page-annuaire page-annuaire-listing" data-theme="secondary-25">
      <div className="page_inner" data-uk-filter="target: .profiles-filter">

        <section className="section section-main section-page section-header header-grid section-register" data-uk-sticky="media: 960; offset: 0px;">

          <div className={`tools-search section_inner radius radius-small radius-right  ${boxfilter ? 'filters-open' : 'filters-close'}`} data-theme="primary" data-uk-sticky="target-offset #mainFooter; media: (max-width: 767px);">
            <Search members={members} mode='alone' setFilters={saveFilters} placeholder={`Mots-clés, personnes, projets...`} />

            <div className="grid_header">
              <div className="grid_header_inner" data-theme="primary">
                <div className="grid_header_inner-title">

                  <CountUp
                    start={0}
                    end={filteredMembers.length}
                    duration={0.75}
                    separator=" "
                    decimals={0}
                    decimal=","
                    prefix=""
                    suffix=""
                  // onEnd={() => console.log('Ended! 👏')}
                  // onStart={() => console.log('Started! 💨')}
                  />
                  <span className="upp"> contact{1 < filteredMembers.length ? 's' : ''}</span>
                </div>
                <div className="grid_header_inner-actions">
                  <span className="legend">Tri alphabétique</span>
                  <div className="uk-active" data-uk-filter-control="sort: data-name; order: asc;">
                    <button className="btn btn-sort btn-no-text">
                      <span className="btn_icon"><i className="fas fa-sort-alpha-down"></i></span>
                      <span className="btn_label">De A à Z</span>
                    </button>
                  </div>
                  <div data-uk-filter-control="sort: data-name; order: desc;">
                    <button className="btn btn-sort btn-no-text">
                      <span className="btn_icon"><i className="fas fa-sort-alpha-down-alt"></i></span>
                      <span className="btn_label">De Z à A</span>
                    </button>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <CitySkyline />
        </section>


        <div className="section-annuaire listing">
          <div className={`filters filters-box ${boxfilter ? 'box-open' : 'box-close'}`}>
            <div className="filters-box_inner">
              <div className="filters-container"
              // data-uk-sticky="offset: 400px;"
              >
                <Filters members={members} filteredMembers={filteredMembers} filters={filters} setFilters={saveFilters} getFiltersCount={getFiltersCount} />
                <button className="btn btn-box-close btn-white btn-no-text" onClick={() => onChangeFilterBox()}>
                  <span className="btn_icon"><i className="fas fa-filter"></i></span>
                  <span className="btn_label">Ouvrir les filtres</span>
                </button>
              </div>
            </div>
          </div>


          <div className="grid grid-profile">



            <div className="grid_inner profiles-filter">
              {0 === filteredMembers.length ?
                <div className="no-result"><span>Aucune donnée disponible avec cette recherche</span></div>
                :
                filteredMembers.slice(0, (page * maxPerPage)).map((m: any) => (
                  <Card className="card card-profile" key={m.id} type="full" data={m} color={Math.floor(Math.random() * 4)} />
                ))
              }
            </div>
          </div>



          {(0 < filteredMembers.length && page < maxPage) &&
            <ViewportBlock
              onEnterViewport={() => setPage(page + 1)}
            // onLeaveViewport={() => console.log('leave')} 
            />
          }


        </div>
      </div>
    </div>
  );
}

export default MembersPage;