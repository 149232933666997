import React, { FunctionComponent, useEffect, useState } from 'react';
import { PERIMETRE, selected } from '../../../../../templates/PortraitTemplate';

import "./styles.scss";


const Reunion: FunctionComponent<{
    data: number
}> = ({
    data
}) => {

        return (
            <div className="map-wrapper-mini">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
                    <g className={`map-svg map-recap map-reunion map-fr-outre-mer`} strokeWidth="2">
                        <path data-color={data} fill="currentColor" d="M78.25 47L65.41 34v-5L52.25 15.84H34.08l-7.16 7h-7.33v4.66l-7.84 8v6.83l8.34 8.5v4.5L36.75 72h12.83l2.17 2.16h20l5-5V62l-4.5-4.5 6-6z" />
                    </g>
                </svg>
            </div>
        )
    }

export default Reunion;

