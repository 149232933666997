import React, { useEffect, useState } from "react"
import { Member } from "../../../../../services/MemberService";
import { clone } from '../../../../../utils/File';
import { isAuthorized } from '../../../../../services/AuthService';
import Fuse from 'fuse.js';
import { defaultFilters, getDepartment, uniqBy } from "../../../../../utils/Data";
import ButtonDownload from "../../../../element/Buttons/ButtonDownload";
import { DateTime } from 'luxon';
import * as cryptoJS from 'crypto-js';

const Filters: React.FunctionComponent<{ members: Member[], filteredMembers: Member[], filters: any, setFilters: any, getFiltersCount: any }> = ({ members, filteredMembers, filters, setFilters, getFiltersCount }) => {

    const [filtersCount, setFiltersCount] = useState<number>(0);

    const [showedThemes, showThemes] = useState<boolean>(false);

    const [showedTypes, showTypes] = useState<boolean>(false);

    const [searchLocation, setSearchLocation] = useState<string>('');

    const [resultsSearchLocation, setResultsSearchLocation] = useState<any>({
        region: [],
        department: [],
        city: [],
        qpv: []
    });

    const searchOptions = {
        includeScore: true,
        includeMatches: true,
        useExtendedSearch: true,
        isCaseSensitive: false,
        threshold: 0.1,
        keys: [{
            name: 'qpv',
            weight: 5
        }, {
            name: 'city',
            weight: 4
        }, {
            name: 'region',
            weight: 4
        }, {
            name: 'department',
            weight: 4
        }, {
            name: 'postalCode',
            weight: 4
        }, {
            name: 'address',
            weight: 3
        }]
    }

    const contactTypes: any = [
        { label: 'Actrice, acteur (associatif, économique, élu...)', value: 'Actrice, acteur (associatif, économique, élu...)' },
        { label: 'Habitante, habitant', value: 'Habitante, habitant' }
    ]

    const thematics: any = [
        { label: 'Citoyenneté', value: 'Citoyenneté' },
        { label: 'Commerce-Entreprise', value: 'Commerce-Entreprise' },
        { label: 'Culture', value: 'Culture' },
        { label: 'Emploi', value: 'Emploi' },
        { label: 'Jeunesse', value: 'Jeunesse' },
        { label: 'Le vivre-ensemble', value: 'Le vivre-ensemble' },
        { label: 'Logement/Urbanisme', value: 'Logement/Urbanisme' },
        { label: 'Médiation', value: 'Médiation' },
        { label: 'Santé', value: 'Santé' },
        { label: 'Vie quotidienne', value: 'Vie quotidienne' }
    ];

    const [thematicsCount, setThematicsCount] = useState<any>({
        'Citoyenneté': 0,
        'Commerce-Entreprise': 0,
        'Culture': 0,
        'Emploi': 0,
        'Jeunesse': 0,
        'Le vivre-ensemble': 0,
        'Logement/Urbanisme': 0,
        'Médiation': 0,
        'Santé': 0,
        'Vie quotidienne': 0
    });

    const onChangeFilter = (type: string, value: any, subType?: string) => {
        switch (type) {
            case 'thematics':
            case 'types':
                const f = clone(filters[type]);
                if (-1 === f.indexOf(value)) {
                    f.push(value);
                } else {
                    f.splice(f.indexOf(value), 1);
                }
                setFilters({ ...filters, ...{ [type]: f } });
                break;
            // case 'types':
            //     const t = clone(filters.types);
            //     if(-1 === t.indexOf(value)){
            //         t.push(value);
            //     }else{
            //         t.splice(t.indexOf(value), 1);
            //     }
            //     setFilters({...filters, ...{[type]: t}});   
            // break;
            case 'location':
                const l: any = { ...filters.location };
                let fd: any = false;

                // console.log(l);
                // console.log(subType);

                l[subType].forEach((fl: any, fli: any) => {
                    if (fl.qpv === value.qpv) {
                        fd = fli;
                    }
                })

                if (false === fd) {
                    l[subType].push(value);
                } else {
                    l[subType].splice(fd, 1);
                }

                setFilters({
                    ...filters, ...{
                        [type]: l, geolocation: {
                            latitude: null,
                            longitude: null
                        }
                    }
                });
                setResultsSearchLocation({
                    region: [],
                    department: [],
                    city: [],
                    qpv: []
                });
                setSearchLocation('');
                break;
            case 'searchLocation':
                setSearchLocation('' + value);
                break;
            default:
                setFilters({ ...filters, ...{ [type]: value } });
        }
    }


    const geolocateMe = (e: any) => {
        e.preventDefault();
        if ("geolocation" in navigator) {
            if (!filters.geolocation.latitude) {
                navigator.geolocation.getCurrentPosition((position: any) => {
                    setFilters({
                        ...filters, ...{
                            location: {
                                region: [],
                                department: [],
                                city: [],
                                qpv: []
                            },
                            geolocation: {
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude
                            }
                        }
                    })
                }, err => console.log(err.message));
            } else {
                setFilters({
                    ...filters, ...{
                        geolocation: {
                            latitude: null,
                            longitude: null
                        }
                    }
                })
            }
        }
    }

    const countMembers = (thematic: string) => {
        return members.filter((m: any) => -1 !== m.thematics.indexOf(thematic)).length;
    }

    const clearFilters = (e: any) => {
        e.preventDefault();

        const df = clone(defaultFilters);

        if(!!window.localStorage){
            window.localStorage.setItem('filters', JSON.stringify(df));
        }
        setFilters(df);
    }

    const clearGeolocation = (e: any) => {
        e.preventDefault();
        setFilters({
            ...filters, ...{
                geolocation: {
                    latitude: null,
                    longitude: null
                }
            }
        })
    }

    useEffect(() => {
        if (0 < members.length) {
            const tc: any = {}
            for (let i in thematics) {
                tc[thematics[i].value] = countMembers(thematics[i].value);
            }
            // console.log(tc);
            setThematicsCount(tc);
        }
    }, [members]);

    useEffect(() => {
        const count = getFiltersCount();
        setFiltersCount(count);
    }, [filters]);

    useEffect(() => {
        const searchEngine = new Fuse(members, searchOptions);
        let tmp = searchEngine.search((!!searchOptions.useExtendedSearch) ? searchLocation.split(/\s/).join(' | ') : searchLocation);

        let results = {
            region: [],
            department: [],
            city: [],
            qpv: []
        };

        tmp.forEach((r: any) => {
            r.matches.forEach((m: any) => {
                switch (m.key) {
                    case 'city':
                    case 'address':
                        results.city.push({
                            id: cryptoJS.MD5(r.item.city.toLowerCase()).toString(),
                            name: r.item.city
                        });
                        break;
                    case 'qpv':
                        results.qpv.push({
                            id: cryptoJS.MD5(r.item.qpv.toLowerCase()).toString(),
                            name: r.item.qpv
                        });
                        break;
                    case 'region':
                        results.region.push({
                            id: cryptoJS.MD5(r.item.region.toLowerCase()).toString(),
                            name: r.item.region
                        });
                        break;
                    case 'department':
                        results.department.push({
                            id: cryptoJS.MD5(r.item.department.toLowerCase()).toString(),
                            name: r.item.department
                        });

                        break;
                }
            })
        });

        results = {
            region: uniqBy(results.region, (r: any) => r.id),
            city: uniqBy(results.city, (c: any) => c.id),
            qpv: uniqBy(results.qpv, (q: any) => q.id),
            department: uniqBy(results.department, (d: any) => d.id)
        }

        // console.log(results);

        setResultsSearchLocation(results);
    }, [searchLocation]);

    return (
        <>
            <h3>Filtrer ma recherche</h3>
            <form className="form form-filters" noValidate onSubmit={(e) => e.preventDefault()}>
                <div className="form_inner">
                    {(!showedThemes && !showedTypes) ?
                        <>
                            <div className="form-row row row-block row-filter filter-location row-autocomplete">
                                <label><span>Précisez une localisation</span></label>
                                <div className="input-wrapper">
                                    <input type="text" value={searchLocation} placeholder='Région, département, commune, QPV' onChange={(e) => onChangeFilter('searchLocation', e.currentTarget.value)} />
                                    <div className={`search-results ${0 < resultsSearchLocation.region.length || 0 < resultsSearchLocation.city.length || 0 < resultsSearchLocation.qpv.length || 0 < resultsSearchLocation.department.length ? "search-open" : null}`}>

                                        <div className="search-results_inner">
                                            <div className="search-results-group">
                                                {0 < resultsSearchLocation.region.length &&
                                                    <div className="search-results-subgroup">
                                                        <label>Région</label>
                                                        <ul>
                                                            {resultsSearchLocation.region.map((r: any) => (
                                                                <li key={r.id} onClick={() => onChangeFilter('location', r, 'region')}>
                                                                    <span>
                                                                        {r.name}
                                                                    </span>
                                                                </li>
                                                            ))
                                                            }
                                                        </ul>
                                                    </div>
                                                }
                                                {0 < resultsSearchLocation.department.length &&
                                                    <div className="search-results-subgroup">
                                                        <label>Département</label>
                                                        <ul>
                                                            {resultsSearchLocation.department.map((d: any) => (
                                                                <li key={d.id} onClick={() => onChangeFilter('location', d, 'department')}>
                                                                    <span>
                                                                        <button>
                                                                            {d.name}
                                                                        </button>
                                                                    </span>
                                                                </li>
                                                            ))
                                                            }
                                                        </ul>
                                                    </div>
                                                }
                                                {0 < resultsSearchLocation.city.length &&
                                                    <div className="search-results-subgroup">
                                                        <label>Commune</label>
                                                        <ul>
                                                            {resultsSearchLocation.city.map((c: any) => (
                                                                <li key={c.id} onClick={() => onChangeFilter('location', c, 'city')}>
                                                                    <span>
                                                                        <button>
                                                                            {c.name}
                                                                        </button>
                                                                    </span>
                                                                </li>
                                                            ))
                                                            }
                                                        </ul>
                                                    </div>
                                                }
                                                {0 < resultsSearchLocation.qpv.length &&
                                                    <div className="search-results-subgroup">
                                                        <label>QPV</label>
                                                        <ul>
                                                            {resultsSearchLocation.qpv.map((q: any) => (
                                                                <li key={q.id} onClick={() => onChangeFilter('location', q, 'qpv')}>
                                                                    <span>
                                                                        <button>
                                                                            {q.name}
                                                                        </button>
                                                                    </span>
                                                                </li>
                                                            ))
                                                            }
                                                        </ul>
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                    </div>

                                </div>

                                {/* <input type="text" value={filters.location} placeholder='Région, départ, commune, QPV' onChange={(e) => onChangeFilter('location', e.currentTarget.value)} /> */}
                            </div>
                            <div className="form-row row row-block row-filter filter-geolocation">
                                <label><span>Ou localisez-vous</span></label>
                                <div className="row_inner">
                                    <div className="filter-geolocate-me">
                                        <button className={`btn btn-primary-50 btn-geoloc btn-no-text rounded ${!!filters.geolocation.latitude ? 'active' : 'not-active'}`} onClick={geolocateMe}>
                                            <span className="btn_icon"><i className="fa fa-location-arrow"></i></span>
                                            <span className="btn_label">Se géolocaliser</span>
                                        </button>
                                    </div>

                                    <div className="filter-slider">
                                        <div className={`slider-mark ${(10 === filters.radius && !!filters.geolocation.latitude) ? 'selected' : ''}`} onClick={() => onChangeFilter('radius', 10)}>
                                            <div className="slider-mark-info">
                                                <span>10km</span>
                                            </div>
                                            <div className={`slider-mark-check`}></div>
                                        </div>
                                        <div className={`slider-mark ${(30 === filters.radius && !!filters.geolocation.latitude) ? 'selected' : ''}`} onClick={() => onChangeFilter('radius', 30)}>
                                            <div className="slider-mark-info">
                                                <span>30km</span>
                                            </div>
                                            <div className={`slider-mark-check`}></div>
                                        </div>
                                        <div className={`slider-mark last-mark ${(100 === filters.radius && !!filters.geolocation.latitude) ? 'selected' : ''}`} onClick={() => onChangeFilter('radius', 100)}>
                                            <div className="slider-mark-info">
                                                <span>60km et +</span></div>
                                            <div className={`slider-mark-check`}></div>
                                        </div>
                                        <span className="slider-legend">autour de vous</span>
                                    </div>

                                    {/* <Slider
                                    defaultValue={filters.radius}
                                    min={10}
                                    step={10 === filters.radius ? 20 : 30}
                                    max={60}
                                    graduated
                                    progress
                                    renderMark={(mark:any) => {
                                        return mark;
                                    }}
                                    /> */}
                                </div>
                            </div>
                            <div className="form-row row row-block row-filter filter-select">
                                <label><span>Sélectionnez un ou plusieurs types de contacts</span></label>
                                <button className={`btn btn-filter rounded ${0 < filters.types.length ? 'selected' : ''}`} onClick={() => showTypes(true)}>
                                    <span className="btn_label">Types de contacts</span>
                                    <span className="btn_icon"><i className="fa fa-plus"></i></span>
                                </button>
                                {/* <div className={`button-filter ${0 < filters.types.length ? 'selected' : ''}`} onClick={() => showTypes(true)}><span>Types de contacts</span> <i className="fa fa-plus"></i></div> */}
                            </div>
                            <div className="form-row row row-block row-filter filter-select">
                                <label>Sélectionnez un ou plusieurs thèmes</label>
                                <button className={`btn btn-filter rounded ${0 < filters.thematics.length ? 'selected' : ''}`} onClick={() => showThemes(true)}>
                                    <span className="btn_label">Thèmes</span>
                                    <span className="btn_icon"><i className="fa fa-plus"></i></span>
                                </button>
                                {/* <div className={`button-filter ${0 < filters.thematics.length ? 'selected' : ''}`} onClick={() => showThemes(true)}><span>Thèmes</span> <i className="fa fa-plus"></i></div> */}
                            </div>
                            <div className="form-row row row-block row-filter filter-summary">
                                {/* {filters.thematics.length} filtre{1 < filters.thematics.length ? 's' : ''} actif{1 < filters.thematics.length ? 's' : ''} */}
                                <div className={`filter-summary-title ${0 < filtersCount ? 'active' : ''}`}>
                                    {filtersCount} filtre{1 < filtersCount ? 's' : ''} actif{1 < filtersCount ? 's' : ''}
                                </div>
                                {0 < filtersCount &&
                                    <div className="filter-summary-reset">
                                        <button className="link link-reset" onClick={clearFilters}>
                                            <span className="link_label">
                                                Annuler tous les filtres
                                            </span>
                                            <span className="link_icon">
                                                <i className="fas fa-times"></i>
                                            </span>
                                        </button>
                                    </div>
                                }
                            </div>

                            {!!filters.location.region && 0 < filtersCount &&
                                <div className="form-row row row-block row-filter filter-summary-selected">
                                    <div className="list">
                                        {filters.location.region.map((r: any) => (
                                            <div key={r.id}>
                                                <button className="btn-filter-remote" onClick={() => onChangeFilter('location', r, 'region')}>
                                                    <span className="btn_label">{r.name}</span>
                                                    <span className="btn_icon"><i className='fa fa-times'></i></span>
                                                </button>
                                            </div>
                                        ))}
                                        {filters.location.department.map((d: any) => (
                                            <div key={d.id}>
                                                <button className="btn-filter-remote" onClick={() => onChangeFilter('location', d, 'department')}>
                                                    <span className="btn_label">{d.name}</span>
                                                    <span className="btn_icon"><i className='fa fa-times'></i></span>
                                                </button>
                                            </div>
                                        ))}
                                        {filters.location.city.map((c: any) => (
                                            <div key={c.id}>
                                                <button className="btn-filter-remote" onClick={() => onChangeFilter('location', c, 'city')}>
                                                    <span className="btn_label">{c.name}</span>
                                                    <span className="btn_icon"><i className='fa fa-times'></i></span>
                                                </button>
                                            </div>
                                        ))}
                                        {filters.location.qpv.map((q: any) => (
                                            <div key={q.id}>
                                                <button className="btn-filter-remote" onClick={() => onChangeFilter('location', q, 'qpv')}>
                                                    <span className="btn_label">{q.name}</span>
                                                    <span className="btn_icon"><i className='fa fa-times'></i></span>
                                                </button>
                                            </div>
                                        ))}
                                        {!!filters.geolocation.latitude &&
                                            <div>
                                                <button className="btn-filter-remote" onClick={clearGeolocation}>
                                                    <span className="btn_label">Ma localisation</span>
                                                    <span className="btn_icon"><i className='fa fa-times'></i></span>
                                                </button>
                                            </div>
                                        }
                                        {filters.types.map((t: string) => (
                                            <div key={t}>
                                                <button className="btn-filter-remote" onClick={() => onChangeFilter('types', t)}>
                                                    <span className="btn_label">{t}</span>
                                                    <span className="btn_icon"><i className='fa fa-times'></i></span>
                                                </button>
                                            </div>
                                        ))}
                                        {filters.thematics.map((t: string) => (
                                            <div key={t}>
                                                <button className="btn-filter-remote" onClick={() => onChangeFilter('thematics', t)}>
                                                    <span className="btn_label">{t}</span>
                                                    <span className="btn_icon"><i className='fa fa-times'></i></span>
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }

                        </>
                        : !!showedThemes ?
                            <div className="form-row row row-block row-filter filter-list">
                                <button className="btn btn-back-remote" onClick={(e) => { e.preventDefault(); showThemes(false) }}>
                                    <span className="btn_icon">
                                        <i className="fas fa-arrow-left"></i>
                                    </span>
                                    <span className="btn_label upp">
                                        thèmes
                                    </span>
                                </button>
                                <ul className="filters-list">
                                    {0 < thematics.length &&
                                        thematics.map((t: any) => (
                                            <li className="filters-filter" key={t.value}>
                                                <button className={`btn btn-filter invert rounded ${-1 !== filters.thematics.indexOf(t.value) ? 'selected' : ''}`} onClick={() => onChangeFilter('thematics', t.value)}>
                                                    <span className="btn_label">{t.label} <small>{thematicsCount[t.value]}</small></span>
                                                    <span className="btn_icon"><i className={`fa ${-1 !== filters.thematics.indexOf(t.value) ? 'fa-times' : 'fa-plus'}`}></i></span>
                                                </button>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                            :
                            <div className="form-row row row-block row-filter filter-list">
                                <button className="btn btn-back-remote" onClick={(e) => { e.preventDefault(); showTypes(false) }}>
                                    <span className="btn_icon">
                                        <i className="fas fa-arrow-left"></i>
                                    </span>
                                    <span className="btn_label upp">
                                        Types de contacts
                                    </span>
                                </button>
                                <ul className="filters-list">
                                    {0 < contactTypes.length &&
                                        contactTypes.map((ct: any) => (
                                            <li className="filters-filter" key={ct.value}>
                                                <button className={`btn btn-filter invert rounded ${-1 !== filters.types.indexOf(ct.value) ? 'selected' : ''}`} onClick={() => onChangeFilter('types', ct.value)}>
                                                    <span className="btn_label">{ct.label}</span>
                                                    <span className="btn_icon"><i className={`fa ${-1 !== filters.types.indexOf(ct.value) ? 'fa-times' : 'fa-plus'}`}></i></span>
                                                </button>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                    }
                </div>
                {(!!isAuthorized && 0 < members.length) &&
                    <div className="form-row row row-block row-button-download">
                        <ButtonDownload
                            buttonTitle='Exporter ma requête en CSV'
                            data={filteredMembers}
                            filename={`list-membres-${DateTime.local().toFormat('d-L-y')}.csv`}
                        />
                    </div>
                }
            </form>
        </>
    );
}

export default Filters;