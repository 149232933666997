import React from "react";
import Link from 'gatsby-plugin-transition-link';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import CitySkyline from "../../../../element/Svg/CitySkyline";

const Description = () => {

  return (
    <section className="section section-main section-page section-header section-description">
      <div className="section_inner radius radius-xl radius-right" data-theme="primary">
        <div className="section-title">
          <h1><b>L'annuaire des quartiers</b></h1>
        </div>
        <div className="section-content">
          <p>
            <strong>"Vu des quartiers"</strong> met en valeur des contacts habitant et/ou agissant dans les quartiers prioritaires de la politique de la ville. C'est un outil. <strong>Une façon de créer un lien entre les journalistes et les habitants et contacts des quartiers prioritaires</strong> qui veulent échanger avec la presse, être des interlocuteurs, raconter ce qui se passe chez eux.
          </p>
          <p>
            C'est un annuaire dans lequel les personnes volontaires pour parler de leur quartier sont référencées. <strong><u>L’annuaire ne peut être consulté</u> que par les journalistes accrédités, qui appellent les habitants et contacts quand ils en ont besoin, et ces habitants et contacts qui ont accepté d'en faire partie.</strong>
          </p>
          <div className="cta-wrapper">
            <AniLink
              fade
              to="/charte-d-utilisation"
              entry={{
                delay: 0.5
              }}
            >
              <button className="btn btn-cta btn-secondary invert rounded">
                <span className="btn_label">Consulter notre charte d'utilisation</span>
                <span className="btn_icon"><i className="fas fa-arrow-right"></i></span>
              </button>
            </AniLink>
          </div>
        </div>
        <CitySkyline />
      </div>
    </section>
  );
}

export default Description;